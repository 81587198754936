import React, { Component } from "react";
import "./usermanagement.css";
import axios from "axios";
const API_URL = "";

class Usermanagement extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [], accessdenied: true };
    this.callback = this.callback.bind(this);
    this.changeActivated = this.changeActivated.bind(this);
  }

  callback() {
    console.log(this.state.acc);
  }

  componentDidMount() {

    const { email, password } = this.props.match.params;

    console.log(email, password);
    axios
      .get(
        API_URL + "checkUserManager?email=" + email + "&password=" + password
      )
      .then(response => {
        if (!response.data.success) {
          this.setState({ accessdenied: true }, () => {
            this.callback();
          });
          alert(response.data.message);
        } else {
          axios.get(API_URL + "getUsers").then(response => {
            this.setState({ users: response.data, accessdenied: false }, () => {
              this.callback();
            });
          });
          // this.setState({ accessdenied: false }, () => {
          //   this.callback();
          // });
        }
      })
      .catch(err => {
        console.log("Fehler: ", err);
      });
  }

  changeActivated(e) {
    let uid = this.state.users[e.target.id]._id;
    let checker = e.target.checked;
    let myUsers = this.state.users;
    myUsers[e.target.id].activated = checker;

    this.setState({ users: myUsers });

    console.log(checker);

    axios
      .get(
        API_URL +
          "updateActivated?uid=" +
          uid +
          "&activated=" +
          myUsers[e.target.id].activated
      )
      .then(response => {
        console.log(response.data);
        //document.getElementById("masterElement").style.opacity = 0;
        //this.setState({ showagain: checker });
      })
      .catch(err => {
        console.log("Fehler: ", err);
      });
  }

  render() {
    let users = Object.values(this.state.users);
    if (this.state.accessdenied) {
      return <div className="userlist">no permission to access</div>;
    } else {
      return (
        <div className="userlist">
          <h1 className="userHeader">Userlist</h1>
          <div className="tableHeader">
            <div className="tableHeaderuserData">
              <p>Name</p>
              <p>Email</p>
            </div>
            <p>Activated</p>
          </div>
          <div className="userContent">
            {users.map((user, index) => {
              return (
                <div className="userItem" key={index}>
                  <div className="userData">
                    <p type="">{user.firstName + " " + user.lastName}</p>
                    <p>{user.email}</p>
                  </div>
                  <label className="container">
                    <input
                      type="checkbox"
                      checked={user.activated}
                      dafaultchecked="false"
                      id={index}
                      ref={"checkActivatedUser" + index}
                      onChange={this.changeActivated}
                    ></input>
                    <span className="checkmarkUser"></span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}

export default Usermanagement;

//
// {users.map(users => {
//   return <div>hallo</div>;
// })}

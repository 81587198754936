import React, { Component } from "react";
import "./container.css";
import MasterElement from "./master-element";
// import ErrorBoundary from "./ErrorBoundary"

import { getFromStorage } from "../utils/storage";
import axios from "axios";
const API_URL = "";

let player = require("../req/playSound");

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      editorMode: 0,
      isLoading: true,
      signUpError: "",
      signUpFirstName: "",
      signUpLastName: "",
      signUpEmail: "",
      signUpPassWord: "",
      confirmSignUpPassWord: "",
      signInError: "",
      signInEmail: "",
      signInPassWord: "",
      userName: "",
      userId: "",
      howto: undefined,
      activated: false,
      firstSignUp: false
    };
    this.showPass = this.showPass.bind(this);
    this.logIn = this.logIn.bind(this);
    this.logOut = this.logOut.bind(this);
    this.InputChange = this.InputChange.bind(this);
    this.changeLoginMode = this.changeLoginMode.bind(this);
    this.closeConfirmSubmit = this.closeConfirmSubmit.bind(this);
  }

  componentDidMount() {

    let token = getFromStorage("the_main_app");

    if (token) {
      fetch("verify?token=" + token)
        .then(res => {
          res.json();
        })
        .then(json => {
          if (json.success) {
            this.setState({
              token,
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  closeConfirmSubmit(e) {
    this.setState({ editorMode: 0 });
  }

  showPass(e) {
    let c = e.target.parentElement.children;
    let c1 = c[c.length - 2];
    let c2 = c[c.length - 3];
    let ct = c1.type;
    if (ct === "password") {
      c1.type = c2.type = "text";
    } else {
      c1.type = c2.type = "password";
    }

    //console.log(c, ct);
  }

  logIn(e) {

    const {
      signInEmail,
      signInPassWord,
      signUpFirstName,
      signUpLastName,
      signUpEmail,
      signUpPassWord,
      confirmSignUpPassWord,
      token
    } = this.state;
    let body = {};
    if (e.target.id === "signin") {
      body = {
        email: signInEmail,
        password: signInPassWord,
        token: token,
        signin: true
      };
    } else {
      body = {
        firstName: signUpFirstName,
        lastName: signUpLastName,
        email: signUpEmail,
        password: signUpPassWord,
        confirmPassword: confirmSignUpPassWord,
        token: token,
        signin: false
      };

      //body = JSON.stringify(body);
    }

    axios
      .post(API_URL + e.target.id, body)
      .then(response => {
        if (!response.data.success) {
          alert(response.data.message);
        } else {
          if (body.signin) {
            axios
              .get(API_URL + "verify?token=" + response.data.token)
              .then(response => {
                this.setState({
                  userName: response.data.name,
                  token: response.data.token,
                  userId: response.data.userId,
                  howto: response.data.howto,
                  activated: response.data.active
                });
              })
              .catch(err => {
                console.log("Fehler: ", err);
              });

            //if (this.state.activated) {
            this.setState({ editorMode: 1 });
            player.checkMaxChannelNum();
            //}
          } else {
            this.setState({
              howto: true,
              userName: response.data.name,
              userId: response.data.userId,
              firstSignUp: true,
              editorMode: 2
            });
          }

          // document.getElementById("masterElement").style.opacity = 1;
        }
      })
      .catch(err => {
        console.log("Error: ", err);
      });
  }

  logOut(e) {
    axios
      .get(API_URL + "logout?token=" + this.state.token)
      .then(response => {
        document.getElementById("masterElement").style.opacity = 0;
        this.setState({ editorMode: 0, token: false, howto: undefined });
      })
      .catch(err => {
        console.log("Fehler: ", err);
      });

    this.refs["masterElement"].mylogout();
  }

  InputChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  changeLoginMode(e) {
    let inForm = document.getElementById("signin");
    let inButton = document.getElementsByName("signinButton")[0];
    let inHint = document.getElementById("inHint");
    let upForm = document.getElementById("signup");
    let upButton = document.getElementsByName("signupButton")[0];
    let upHint = document.getElementById("upHint");

    if (e.target.id === "inHint" || e.target.id === "outHint" || e.target.id ==="inTextHint") {
      inForm.style.display = inButton.style.display = inHint.style.display =
        "none";

      upForm.style.display = upButton.style.display = upHint.style.display =
        "flex";
    } else {
      inForm.style.display = inButton.style.display = inHint.style.display =
        "flex";

      upForm.style.display = upButton.style.display = upHint.style.display =
        "none";
    }
  }

  render() {
    const {
      isLoading,
      token,
      signInError,
      signInEmail,
      signInPassWord,
      signUpFirstName,
      signUpLastName,
      signUpEmail,
      signUpPassWord,
      confirmSignUpPassWord
    } = this.state;

    if (isLoading) {
      return (
        <div>
          <p>Loading</p>
        </div>
      );
    }
    if (!token && this.state.editorMode === 0) {
      return (
        <div className="login">
          <div className="mainHeader">
            <h1 className="signInHeader">
              — <br />
              nuilab
              <br />
              pulse <br />
              designer
              <br />
            </h1>
            <div className="introText">
              <p>
                Design your own haptic feedbacks for the nuilab pulse core
                devkit. Just sign in if you still have an account. Otherwise
                please <button className="textButton" id="inTextHint" onClick={this.changeLoginMode}> create an account.</button>
                <br />
                <br />
                You can use the nuilab pulse core designer at its best with
                google’s chrome-browser. If you prefer to work with an other
                browser please select the pulse core dev kit as your computer’s
                audio-output device at the system preferences before running the
                pulse designer. <br />

              </p>
            </div>
          </div>
          <div className="loginForm" id="signin">
            {signInError ? <p>{signInError}</p> : null}
            <input
              className="logInInput"
              type="email"
              placeholder="eMail"
              value={signInEmail}
              id="signInEmail"
              onChange={this.InputChange}
            />
            <input
              className="logInInput"
              type="password"
              placeholder="Password"
              value={signInPassWord}
              id="signInPassWord"
              onChange={this.InputChange}
            />
            <input
              className="passButton signInPassButton"
              type="button"
              value=""
              onClick={this.showPass}
            />
          </div>
          <input
            className="submitLogin"
            type="button"
            value="sign in"
            name="signinButton"
            id="signin"
            onClick={this.logIn}
          />
          <button
            className="hint"
            id="inHint"
            onClick={this.changeLoginMode}
          >
            The first time? Create an account here.
          </button>

          <div className="loginForm" id="signup">
            <input
              className="logInInput"
              type="text"
              placeholder="First Name"
              value={signUpFirstName}
              id="signUpFirstName"
              onChange={this.InputChange}
            />
            <input
              className="logInInput"
              type="text"
              placeholder="Last Name"
              value={signUpLastName}
              id="signUpLastName"
              onChange={this.InputChange}
            />
            <input
              className="logInInput"
              type="email"
              placeholder="eMail"
              value={signUpEmail}
              id="signUpEmail"
              onChange={this.InputChange}
            />
            <input
              className="logInInput"
              type="password"
              placeholder="Password"
              value={signUpPassWord}
              id="signUpPassWord"
              onChange={this.InputChange}
            />
            <input
              className="logInInput"
              type="password"
              placeholder="Confirm Password"
              value={confirmSignUpPassWord}
              id="confirmSignUpPassWord"
              onChange={this.InputChange}
            />
            <input
              className="passButton"
              type="button"
              value=""
              onClick={this.showPass}
            />
          </div>
          <input
            className="submitLogin"
            type="button"
            value="Sign Up"
            name="signupButton"
            id="signup"
            onClick={this.logIn}
          />
          <button
            className="hint"
            id="upHint"
            onClick={this.changeLoginMode}
          >
            You have already an account? Sign in!
          </button>
          <br />
          <br />
          <div className="termsAndDisclaim">
          <button
            className="hint"
            id="inHint"
            onClick={this.changeLoginMode}
          >
            Terms of Use
          </button>

          <button
            className="hint"
            id="inHint"
            onClick={this.changeLoginMode}
          >
            Disclaimer
          </button>
            </div>
        </div>
      );
    }
    if (this.state.editorMode === 1) {
      if (this.state.howto === undefined) {
        return <div>…loading</div>;
      } else {
        return (
          <div
            id="masterElement"
            style={{ opacity: 1 }}
            className="masterElement"
          >
            <MasterElement
              usr={this.state.userId}
              howto={this.state.howto}
              ref="masterElement"
            />
            <div className="profile" style={{ display: "inline" }}>
              <p>
                Hello, <br />
                {this.state.userName}
                <br />
                <button id="outHint" onClick={this.logOut}>
                  Log Out
                </button>
              </p>
            </div>
          </div>
        );
      }
    }
    if (this.state.editorMode === 2) {
      return (
        <div className="confirmSignin">
          <div className="confiremSigninContent">
            <h2>You signed up sucessfully!</h2>
            <p>
              Thank you for creating an account for the nuilab pulseDesigner.
              <br />
              <br />
              Our pulseDesigner enables you to design stunning haptic
              vibraeffects tailor-made for our pulseCore actuator family. <br />
              <br />
              Before you can start designing you own haptic vibraeffects, we
              have to activate your account. We will do that as soon as possible
              and send you a message if it's activated. So please be patient so
              far.
              <br />
              <br />
            </p>

            <div onClick={this.closeConfirmSubmit}>
              <button>Back</button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Container;

//<br /> For detailed use information click <button className="textButton"> here</button>

import React, { Component } from "react";
import "./drawer.css";
import paper from "paper";
import _ from "lodash";

let path;
let handlePath;
let cleanedPath;
let coordinatePath;
let currentTool = "drawer";
let handlerToDrag = false;
let mouseOffset;
let activeHandler;
let activeHandlebar = false;
// let myKey = false;
let analyzerGroup = [];
let drawDirection;
let myHandlers;
let cursorClass;
let flipH = false;
let flipV = false
// let canvasBound;



let hitOptions = {
  strokeColor: "darkgrey",
  // segments: true,
  stroke: true,
  fill: false,
  tolerance: 7
};

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathDrawer: false,
      pathEditor:false,
      handlers: [],
      myCurve: [],
      canvasEntered: false
    };
    this.pathStart = this.pathStart.bind(this);
    this.pathDraw = this.pathDraw.bind(this);
    this.pathStop = this.pathStop.bind(this);
    this.selectTool = this.selectTool.bind(this);
    this.enterCanvas = this.enterCanvas.bind(this);
    this.leaveCanvas = this.leaveCanvas.bind(this);
    this.showHandlers = this.showHandlers.bind(this);
    this.enterHandler = this.enterHandler.bind(this);
    this.leaveHandler = this.leaveHandler.bind(this);
    this.startDragHandler = this.startDragHandler.bind(this);
    this.stopDragHandler = this.stopDragHandler.bind(this);
    this.showHandleBars = this.showHandleBars.bind(this);
    this.startDragHandleBar = this.startDragHandleBar.bind(this);
    this.stopDragHandleBar = this.stopDragHandleBar.bind(this);
    this.deselectHandler = this.deselectHandler.bind(this);
    this.hideHandlebars = this.hideHandlebars.bind(this);
    this.analyzePath = this.analyzePath.bind(this);
    this.showCleanedPath = this.showCleanedPath.bind(this);
    this.loadCurve = this.loadCurve.bind(this);
    this.myTimeout = this.myTimeout.bind(this);
    this.callback = this.callback.bind(this);
    this.flipCurve = this.flipCurve.bind(this);
    this.checkHovers = this.checkHovers.bind(this);
  }

  componentDidMount() {

    paper.setup(this.refs["drawCanvas"]);


    for (let i = 0; i < 500; i++) {
      let newLine = new paper.Path();
      if (i % 2 === 0) {
        newLine.strokeColor = new paper.Color(1, 0, 0.5, 0);
      } else {
        newLine.strokeColor = new paper.Color(1, 0, 0.5, 0);
      }
      newLine.add(i + 0.5, 0);
      newLine.add(i + 0.5, 500);
      analyzerGroup.push(newLine);
    }



    cleanedPath = new paper.Path({
      segments: [],

      fillColor: "#eee",
      strokeColor: "#444",
      strokeWidth: 1.5,
      fullySelected: false
    });
     cleanedPath.fillColor.alpha = 0.5;

    coordinatePath = new paper.Path({
      segments: [],
      strokeColor: "darkgrey",
      strokeWidth: 2,
      fullySelected: false
    });
    coordinatePath.add([0, 250]);
    coordinatePath.add([500, 250]);

    path = new paper.Path({
      segments: [],
      strokeColor: "rgb(47, 20, 255)",
      strokeWidth: 2,
      strokeCap: "round",
      fullySelected: false,
      dashArray: [2, 10]
    });

    handlePath = new paper.Path({
      segments: [],
      strokeColor: "rgb(25, 15, 140)",
      strokeWidth: 1,
      fullySelected: false
    });



    // this.props.canvasBound(canvasBound)

  }


checkHovers(e){

  // let mp = this.props.mousePos;
  //
  //
  //  console.log(canvasBound);
  //
  // if(mp[0]<canvasBound.left || mp[0]>canvasBound.right ||
  //    mp[1]<canvasBound.top || mp[1]>canvasBound.bottom
  //  ){
  //
  //
  //  }
}

  flipCurve(direction){

if(path&& path.segments.length>0){
    switch(direction){


      case 'v':
        if(flipV){
          flipV=false
        }else {
          flipV = true
        }


        path.scale(1,-1)
        cleanedPath.scale(1,-1);

        let yOffset = 250 - path.segments[0]._point.y;
        let yOffset2 = 250 - cleanedPath.segments[0]._point.y;

        path.position.y += yOffset;
        cleanedPath.position.y += yOffset2;

      break;
      case 'h':
      if(flipH){
        flipH=false
      }else {
        flipH = true
      }

      path.scale(-1,1)
      cleanedPath.scale(-1,1);

      break;
      default: return;
    };

    activeHandler=false
    this.hideHandlebars()
    this.showHandlers(false);
     this.showCleanedPath();
  }
  }

  posTools(x) {
    setTimeout(
      function() {

        document.getElementById("toolBar").style.marginLeft = 1 + x + "rem";
      },
      100
    );
    // setTimeout(this.myTimeout(x), 50000);
  }

  myTimeout(x) {
    console.log("timeout", document.getElementById("toolBar").style.marginLeft);
    document.getElementById("toolBar").style.marginLeft = 8 + x + "rem";
  }

  pathStart(e) {

     // let mp = this.props.mousePos

    let canvasBound = [this.refs['drawCanvas'].getBoundingClientRect().x,this.refs['drawCanvas'].getBoundingClientRect().y,this.refs['drawCanvas'].getBoundingClientRect().right,this.refs['drawCanvas'].getBoundingClientRect().bottom];
    this.props.canvasBound(canvasBound)
    let boundTop = e.target.getBoundingClientRect();
    let hitPoint = [e.clientX - boundTop.x, e.clientY - boundTop.y];

    let hitResult = paper.project.hitTest(hitPoint, hitOptions);

    if (currentTool === "drawer") {
      this.props.drawerChange(true);
      cleanedPath.segments = [];
      document.getElementById("pointer").style.display = "none";
      document.getElementById("editors").style.display = "none";
      path.segments = [[e.clientX - boundTop.x, 250]];
      this.setState({ pathDrawer: true });
      if (path) {
        path.selected = false;
        // path.remove();
      }
    } else if (currentTool === "pointer") {
      // this.props.drawerChange(true);
      let allHandlers = document.getElementsByClassName("pathHandler");
      for (let i = 0; i < allHandlers.length; i++) {
        allHandlers[i].classList.remove("pathHandlerActive");
        handlePath.removeSegments(0, 2);
        document.getElementById("handleBarGroup").style.display = "none";
      }
    } else if (currentTool === "deleter") {
      if (path) {
        if (hitResult) {
          this.props.drawerChange(true);
          path.insert(hitResult.location.index + 1, hitPoint);
        }
      }
      this.showCleanedPath();
    } else if (currentTool === "curver") {
    }

    this.showHandlers(false);
  }

  // pathDraw(e) {
  //   let mp = this.props.mousePos
  //
  //   // console.log(e.target);
  //
  //   let bound = this.refs['drawCanvas'].getBoundingClientRect();
  //   if (this.state.pathDrawer) {
  //     path.add([e.clientX - bound.x, e.clientY - bound.y]);
  //   }
  //
  //
  //
  //
  //
  //   if (handlerToDrag && currentTool === "pointer") {
  //     this.props.drawerChange();
  //     // handlerToDrag.style.top = e.clientY - mouseOffset[1] - bound.y + "px";
  //     // handlerToDrag.style.left = e.clientX - mouseOffset[0] - bound.x + "px";
  //
  //     let handlerId = parseInt(handlerToDrag.id);
  //     path.segments[handlerId].point.x =
  //       e.clientX - mouseOffset[0] - bound.x + 5;
  //     path.segments[handlerId].point.y =
  //     e.clientY - mouseOffset[1] - bound.y + 5;
  //
  //     let myStateHandlers = this.state.handlers;
  //
  //     myStateHandlers[handlerId - 1] = [
  //       e.clientY - mouseOffset[1] - bound.y,
  //       e.clientX - mouseOffset[0] - bound.x
  //     ];
  //     this.setState({ handlers: myStateHandlers }, () => {
  //       this.callback();
  //     });
  //     this.handleBarPos(e, handlerId, bound);
  //     this.showCleanedPath();
  //
  //
  //
  //   }
  //
  //   if (activeHandlebar) {
  //     this.props.drawerChange();
  //     activeHandlebar.style.top = e.clientY - mouseOffset[1] - bound.y + "px";
  //     activeHandlebar.style.left = e.clientX - mouseOffset[0] - bound.x + "px";
  //
  //     let xx =
  //       activeHandlebar.getBoundingClientRect().left -
  //       activeHandler.getBoundingClientRect().left;
  //     let yy =
  //       activeHandlebar.getBoundingClientRect().top -
  //       activeHandler.getBoundingClientRect().top;
  //
  //     let id = parseInt(activeHandler.id);
  //     path.segments[id][activeHandlebar.id].x = xx;
  //     path.segments[id][activeHandlebar.id].y = yy;
  //     this.handleBarPos(activeHandler, parseInt(activeHandler.id), bound);
  //   }
  //
  //
  // }


  pathDraw(e) {

//console.log(this.props.mousePos);
     if(this.props.mousePos.length===0){

      let canvasBound = [this.refs['drawCanvas'].getBoundingClientRect().x,this.refs['drawCanvas'].getBoundingClientRect().y,this.refs['drawCanvas'].getBoundingClientRect().right,this.refs['drawCanvas'].getBoundingClientRect().bottom];
      this.props.canvasBound(canvasBound)
     }
    let mp = this.props.mousePos





    // console.log(e.target);

    let bound = this.refs['drawCanvas'].getBoundingClientRect();
    if (this.state.pathDrawer) {
      path.add([mp[0] - bound.x, mp[1] - bound.y]);
    }







    if (handlerToDrag && currentTool === "pointer") {
      this.props.drawerChange(true);


      // handlerToDrag.style.top = e.clientY - mouseOffset[1] - bound.y + "px";
      // handlerToDrag.style.left = e.clientX - mouseOffset[0] - bound.x + "px";

      let handlerId = parseInt(handlerToDrag.id);

      path.segments[handlerId].point.x =
        mp[0] - mouseOffset[0] - bound.x + 5;
      path.segments[handlerId].point.y =
        mp[1] - mouseOffset[1] - bound.y + 5;

      let myStateHandlers = this.state.handlers;



      myStateHandlers[handlerId - 1] = [
        mp[1] - mouseOffset[1] - bound.y,
        mp[0] - mouseOffset[0] - bound.x
      ];
      this.setState({ handlers: myStateHandlers }, () => {
        this.callback();
      });
      this.handleBarPos(e, handlerId, bound);
      this.showCleanedPath();



    }

    if (activeHandlebar) {
      this.props.drawerChange(true);
      activeHandlebar.style.top = e.clientY - mouseOffset[1] - bound.y + "px";
      activeHandlebar.style.left = e.clientX - mouseOffset[0] - bound.x + "px";

      let xx =
        activeHandlebar.getBoundingClientRect().left -
        activeHandler.getBoundingClientRect().left;
      let yy =
        activeHandlebar.getBoundingClientRect().top -
        activeHandler.getBoundingClientRect().top;

      let id = parseInt(activeHandler.id);
      path.segments[id][activeHandlebar.id].x = xx;
      path.segments[id][activeHandlebar.id].y = yy;
      this.handleBarPos(activeHandler, parseInt(activeHandler.id), bound);
    }


  }

  handleBarPos(e, handlerId, bound) {

    // let mp = this.props.mousePos;
    document.getElementById("handleIn").style.top =
    e.clientY +
      path.segments[handlerId].handleIn.y -
      mouseOffset[1] -
      bound.y +
      "px";
    document.getElementById("handleIn").style.left =
      e.clientX  +
      path.segments[handlerId].handleIn.x -
      mouseOffset[0] -
      bound.x +
      "px";
    document.getElementById("handleOut").style.top =
      e.clientY  +
      path.segments[handlerId].handleOut.y -
      mouseOffset[1] -
      bound.y +
      "px";
    document.getElementById("handleOut").style.left =
      e.clientX  +
      path.segments[handlerId].handleOut.x -
      mouseOffset[0] -
      bound.x +
      "px";

    if (handlePath.segments.length === 0) {
      // handlePath.add(e.clientX +  path.segments[handlerId].handleIn.x - mouseOffset[0]-bound.x,e.clientY +  path.segments[handlerId].handleIn.y - mouseOffset[1]-bound.y);
      //handlePath.add(0,0);
      handlePath.add(
        e.clientX  +
          path.segments[handlerId].handleIn.x -
          mouseOffset[0] -
          bound.x +
          5,
        e.clientY  +
          path.segments[handlerId].handleIn.y -
          mouseOffset[1] -
          bound.y +
          5
      );
      handlePath.add(
        path.segments[handlerId].point.x,
        path.segments[handlerId].point.y
      );
      handlePath.add(
        e.clientX  +
          path.segments[handlerId].handleOut.x -
          mouseOffset[0] -
          bound.x +
          5,
        e.clientY  +
          path.segments[handlerId].handleOut.y -
          mouseOffset[1] -
          bound.y +
          5
      );
    } else {
      handlePath.segments[0].point.x =
        path.segments[handlerId].point.x + path.segments[handlerId].handleIn.x;
      handlePath.segments[0].point.y =
        path.segments[handlerId].point.y + path.segments[handlerId].handleIn.y;

      handlePath.segments[1].point.x = path.segments[handlerId].point.x;
      handlePath.segments[1].point.y = path.segments[handlerId].point.y;

      handlePath.segments[2].point.x =
        path.segments[handlerId].point.x + path.segments[handlerId].handleOut.x;
      handlePath.segments[2].point.y =
        path.segments[handlerId].point.y + path.segments[handlerId].handleOut.y;
    }
    this.showCleanedPath();
  }

  pathStop() {
     if(this.state.pathDrawer){

    if (currentTool === "drawer") {
      this.setState({ pathDrawer: false });

      this.analyzePath();
      path.simplify(100);
      this.showCleanedPath();
      handlerToDrag = false;

      document.getElementById("pointer").style.display = "inline";
      document.getElementById("editors").style.display = "flex";
    } else {
      document.getElementById("handleIn").classList.remove("pathHandlerActive");
      document
        .getElementById("handleOut")
        .classList.remove("pathHandlerActive");
      document.getElementById("handleBarGroup").style.display = "none";
    }
    }
  }

  analyzePath() {
    // let falsePoints = [];
    // cleanedPath.segments = [];
    let segmentsLength = path.segments.length;
    let lastX = path.segments[segmentsLength - 1].point.x;
    if (path.segments[0].point.x <= lastX) {
      drawDirection = "right";
      path.insert(0, new paper.Point(0, 250));
      path.insert(segmentsLength + 1, new paper.Point(lastX, 250));
      path.insert(segmentsLength + 2, new paper.Point(500, 250));
    } else {
      drawDirection = "left";
      path.insert(segmentsLength + 1, new paper.Point(lastX, 250));
      path.insert(segmentsLength + 2, new paper.Point(0, 250));
      // path.insert(0,new paper.Point(lastX,250));
      path.insert(0, new paper.Point(500, 250));
    }

    let mc = 1;
    while (mc < path.segments.length) {
      if (drawDirection === "right") {
        if (path.segments[mc].point._x - path.segments[mc - 1].point._x >= 0) {
          mc++;
        } else {
          path.removeSegment(mc);
        }
      } else {
        if (path.segments[mc].point._x - path.segments[mc - 1].point._x <= 0) {
          mc++;
        } else {
          path.removeSegment(mc);
        }
      }
    }
  }
showCleanedPath(){
  cleanedPath.segments = [];
    // 	cleanedPath.segments = path.segments;
    // console.log(cleanedPath);
    let cleanedPathPoints = [0];
    cleanedPath.add(0, 250);
    let cornerValues = [0, analyzerGroup.length-1, 1]
    if(flipV){
      cornerValues = [analyzerGroup.length-1, 0, -1]
    }

    for (let i = cornerValues[0]; i !== cornerValues[1]; i+= cornerValues[2]) {
      let secondPath = analyzerGroup[i];
      secondPath.strokeColor = new paper.Color(1, 0, 0.5, 0);

      let intersect = secondPath.getIntersections(path);

      if (intersect.length === 0) {
        cleanedPathPoints.push(0);
        cleanedPath.add(i, 250);
      } else {
        // 		cleanedPath.add(intersect)


        let myPx = intersect[0]._point.x;
        let myPy = intersect[0]._point.y;


        if(flipV && intersect.length >1){

          myPy = intersect[intersect.length-1]._point.y;
        }



        cleanedPathPoints.push((250 - myPy) / 250);
        //console.log(intersect[0]._point);
        cleanedPath.add(myPx, myPy);



      }
    }
    cleanedPathPoints.push(0);

    //this.setState({myCurve:cleanedPathPoints});

    this.props.myCurve({
      bufferCurve: cleanedPathPoints,
      drawPath: path
    }
  );


    cleanedPath.add(500, 250);



}


  deselectHandler() {
    this.refs["pathHandlers"].style.display = "none";

    this.hideHandlebars();
  }

  hideHandlebars() {
    if (handlePath.segments.length !== 0) {
      let allHandlers = document.getElementsByClassName("pathHandler");
      for (let i = 0; i < allHandlers.length; i++) {
        allHandlers[i].classList.remove("pathHandlerActive");
      }

      handlePath.segments[0].point = handlePath.segments[1].point = handlePath.segments[2].point = {
        _x: 0,
        _y: 0
      };
      //console.log(handlePath.segments[0].point, handlePath.segments[1].point, handlePath.segments[2].point);
      document.getElementById("handleBarGroup").style.display = "none";
    }
  }

  selectTool(e) {
    let cl = e.target.classList;
    let tdivs = document.getElementsByClassName("tool");
    let canv = document.getElementById("drawCanvas");
    //	console.log(canv.style.cursor);
    canv.classList.remove(canv.classList[1]);

    if (!_.includes(cl, "activeTool")) {
      for (let i = 0; i < tdivs.length; i++) {
        tdivs[i].classList.remove("activeTool");
      }
      e.target.classList.add("activeTool");
      currentTool = e.target.id;
      switch (currentTool) {
        case "drawer":
          canv.classList.add("cursorPencil");
          this.deselectHandler();
          //console.log(canv.classList);

          //canv.style.cursor = "url('../assets/icons_pencilCursor.cur'),auto"
          break;
        case "pointer":
          this.hideHandlebars();
          canv.classList.add("cursorPointer");
          if (path) {
            this.showHandlers(false);
          }
          this.refs["pathHandlers"].style.display = "inline";

          //canv.style.cursor = "url('../assets/icons_pointerCursor.cur'),auto"
          break;
        case "deleter":
          canv.classList.add("cursorAdder");
          this.hideHandlebars();
          if (path) {
            this.showHandlers(false);
          }
          this.refs["pathHandlers"].style.display = "inline";
          break;
        case "curver":
          canv.classList.add("cursorCurver");
          this.hideHandlebars();
          if (path) {
            this.showHandlers();
          }
          this.refs["pathHandlers"].style.display = "inline";
          break;
          default:

      }
    }
  }

  showHandlers(load) {
    cursorClass = "pathHandler ";
    if (currentTool !== "deleter") {
      cursorClass += document.getElementById("drawCanvas").classList[1];
    } else {
      cursorClass += "cursorDeleter";
    }
    //console.log(cursorClass);

    if (path) {
      let segments = path.segments;
      let handlers = [];
      segments.map((segment, index) => {
        if (index > 0 && index < path.segments.length - 1) {
          return handlers.push([segment._point._y - 5, segment._point._x - 5]);
        } else{
          return 'hi'
        }
      });
      this.setState({ handlers: handlers }, () => {
        this.callback(load);
      });
    }
  }

  showHandleBars(e) {
    if (
      path.segments[e.target.id]._handleIn._x !== 0 ||
      path.segments[e.target.id]._handleOut._x !== 0 ||
      path.segments[e.target.id]._handleIn._y !== 0 ||
      path.segments[e.target.id]._handleOut._y !== 0
    ) {
      path.segments[e.target.id]._handleIn._x = 0;
      path.segments[e.target.id]._handleOut._x = 0;
      path.segments[e.target.id]._handleIn._y = 0;
      path.segments[e.target.id]._handleOut._y = 0;
    } else {
      path.segments[e.target.id]._handleIn._x = -30;
      path.segments[e.target.id]._handleOut._x = 30;
      document.getElementById("handleBarGroup").style.display = "inline";
    }
    this.handleBarPos(
      e,
      e.target.id,
      document.getElementById("drawCanvas").getBoundingClientRect()
    );

    this.showHandlers(false);
  }


  startDragHandler(e) {
    // console.log('tool: ',currentTool);
    let handlerId = parseInt(e.target.id);
    let bound = e.target.getBoundingClientRect();
    mouseOffset = [e.clientX - bound.x, e.clientY - bound.y];
    handlerToDrag = e.target;
    if (activeHandler !== e.target) {
      activeHandler = e.target;
    }
    let allHandlers = document.getElementsByClassName("pathHandler");
    for (let i = 0; i < allHandlers.length; i++) {
      allHandlers[i].classList.remove("pathHandlerActive");
    }
    handlerToDrag.classList.add("pathHandlerActive");

    if (currentTool === "pointer") {
      document.getElementById("handleBarGroup").style.display = "inline";
      handlePath.segments = [];
      this.handleBarPos(
        e,
        handlerId,
        document.getElementById("drawCanvas").getBoundingClientRect()
      );
    } else if (currentTool === "curver") {
      this.props.drawerChange(true);
      this.showHandleBars(e);
      this.handleBarPos(
        e,
        handlerId,
        document.getElementById("drawCanvas").getBoundingClientRect()
      );
    } else if (currentTool === "deleter") {
      //console.log(e.target.id);
      this.props.drawerChange(true);
      path.removeSegment(parseInt(e.target.id));
      handlerToDrag.classList.remove("pathHandlerActive");
      activeHandler = false;
      handlerToDrag = false;
      this.showHandlers(false);
    }
    this.showCleanedPath();
  }

  stopDragHandler() {
    // console.log('up');
    handlerToDrag = false;
    // console.log(this.state.pathDrawer, this.state.pathEditor);
    this.props.drawerChange();
  }

  enterHandler(e) {}

  leaveHandler(e) {}

  startDragHandleBar(e) {
    let canvasBound = [this.refs['drawCanvas'].getBoundingClientRect().x,this.refs['drawCanvas'].getBoundingClientRect().y,this.refs['drawCanvas'].getBoundingClientRect().right,this.refs['drawCanvas'].getBoundingClientRect().bottom];
    this.props.canvasBound(canvasBound)
    let bound = e.target.getBoundingClientRect();
    mouseOffset = [e.clientX - bound.x, e.clientY - bound.y];
    e.target.classList.add("pathHandlerActive");
    activeHandlebar = e.target;
  }

  stopDragHandleBar(e) {
    e.target.classList.remove("pathHandlerActive");
    this.props.drawerChange();
    activeHandlebar = false;
  }

  enterCanvas() {
    // console.log('enter');
  }

  leaveCanvas(e) {

  }

  loadCurve(data) {



    cleanedPath.segments = path.segments = [];
    document.getElementById("pointer").style.display = "inline";
    document.getElementById("editors").style.display = "flex";

    for (let i = 0; i < data.drawPath[1].segments.length; i++) {
      let ii = data.drawPath[1].segments[i];
      if (ii.length === 2) {
        path.add(ii[0], ii[1]);
      } else {
        let p = new paper.Point(ii[0][0], ii[0][1]);
        let hI = new paper.Point(ii[1][0], ii[1][1]);
        let hO = new paper.Point(ii[2][0], ii[2][1]);
        path.add(new paper.Segment(p, hI, hO));
      }
    }
    this.showHandlers(true);
    this.showCleanedPath();
    this.deselectHandler();

    let tdivs = document.getElementsByClassName("tool");
    let canv = document.getElementById("drawCanvas");
    canv.classList.remove(canv.classList[1]);
    canv.classList.add("cursorPencil");

    for (let i = 0; i < tdivs.length; i++) {
      tdivs[i].classList.remove("activeTool");
    }
    document.getElementById("drawer").classList.add("activeTool");
    currentTool = "drawer";



  }

  callback(load) {
    //console.log(load);
  }

  render() {


    myHandlers = this.state.handlers;
    if(this.state.pathDrawer){
      // console.log('mm: ',this.props.mousePos);
      this.pathDraw();
    }


    let myHandleBars = (
      <div>
        <div
          className="pathHandler handleBar"
          id="handleOut"
          onMouseDown={this.startDragHandleBar}
          onMouseUp={this.stopDragHandleBar}
        ></div>
        <div
          className="pathHandler handleBar"
          id="handleIn"
          onMouseDown={this.startDragHandleBar}
          onMouseUp={this.stopDragHandleBar}
        ></div>
      </div>
    );

    return (
      <div className="drawer" >
        <canvas
          className="drawCanvas cursorPencil"
          id="drawCanvas"
          ref="drawCanvas"
          onMouseDown={this.pathStart}
          onMouseMove = {this.pathDraw}
          onMouseUp={this.pathStop}

          width="500"
          height="500"
        ></canvas>
        <div className="pathHandlers" ref="pathHandlers">
          <div
            className="handleBarGroup"
            id="handleBarGroup"
            ref="handleBarGroup"
          >
            {myHandleBars}
          </div>
          {myHandlers.map((handler, index) => {
            return (
              <div
                className={cursorClass}
                key={index + 1}
                id={index + 1}
                style={{
                  top: handler[0] + "px",
                  left: handler[1] + "px"
                }}
                onMouseEnter={this.enterHandler}
                onMouseOut={this.leaveHandler}
                onMouseDown={this.startDragHandler}

                onMouseUp={this.stopDragHandler}
              ></div>
            );
          })}
        </div>
        <div className="toolBarWrapper">
          <div className="toolBar" id="toolBar">
            <div
              className="tool pencil activeTool"
              id="drawer"
              onMouseDown={this.selectTool}
            ></div>
            <div
              className="tool pointer"
              id="pointer"
              onMouseDown={this.selectTool}
            ></div>
            <div className="editors" id="editors">
              <div
                className="tool curver"
                id="curver"
                onMouseDown={this.selectTool}
              ></div>
              <div
                className="tool deleter"
                id="deleter"
                onMouseDown={this.selectTool}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Drawer;
// onMouseMove={this.pathDraw}
//onMouseUp={this.pathStop}
//onMouseOut = {this.checkHovers}
//onMouseOut={this.leaveCanvas}



// pathStart(e) {
//
//    let mp = this.props.mousePos
//
//   let canvasBound = [this.refs['drawCanvas'].getBoundingClientRect().x,this.refs['drawCanvas'].getBoundingClientRect().y,this.refs['drawCanvas'].getBoundingClientRect().right,this.refs['drawCanvas'].getBoundingClientRect().bottom];
//   this.props.canvasBound(canvasBound)
//   let boundTop = e.target.getBoundingClientRect();
//   let hitPoint = [mp[0] - boundTop.x, mp[1] - boundTop.y];
//
//   let hitResult = paper.project.hitTest(hitPoint, hitOptions);
//
//   if (currentTool === "drawer") {
//     this.props.drawerChange();
//     cleanedPath.segments = [];
//     document.getElementById("pointer").style.display = "none";
//     document.getElementById("editors").style.display = "none";
//     path.segments = [[e.clientX - boundTop.x, 250]];
//     this.setState({ pathDrawer: true });
//     if (path) {
//       path.selected = false;
//       // path.remove();
//     }
//   } else if (currentTool === "pointer") {
//     let allHandlers = document.getElementsByClassName("pathHandler");
//     for (let i = 0; i < allHandlers.length; i++) {
//       allHandlers[i].classList.remove("pathHandlerActive");
//       handlePath.removeSegments(0, 2);
//       document.getElementById("handleBarGroup").style.display = "none";
//     }
//   } else if (currentTool === "deleter") {
//     if (path) {
//       if (hitResult) {
//         console.log(hitResult);
//         path.insert(hitResult.location.index + 1, hitPoint);
//       }
//     }
//     this.showCleanedPath();
//   } else if (currentTool === "curver") {
//   }
//
//   this.showHandlers(false);
// }
//
// pathDraw(e) {
//   let mp = this.props.mousePos
//
//   // console.log(e.target);
//
//   let bound = this.refs['drawCanvas'].getBoundingClientRect();
//   if (this.state.pathDrawer) {
//     path.add([mp[0] - bound.x, mp[1] - bound.y]);
//   }
//
//
//
//
//
//   if (handlerToDrag && currentTool === "pointer") {
//     this.props.drawerChange();
//     // handlerToDrag.style.top = e.clientY - mouseOffset[1] - bound.y + "px";
//     // handlerToDrag.style.left = e.clientX - mouseOffset[0] - bound.x + "px";
//
//     let handlerId = parseInt(handlerToDrag.id);
//     path.segments[handlerId].point.x =
//       mp[0] - mouseOffset[0] - bound.x + 5;
//     path.segments[handlerId].point.y =
//       mp[1] - mouseOffset[1] - bound.y + 5;
//
//     let myStateHandlers = this.state.handlers;
//
//     myStateHandlers[handlerId - 1] = [
//       mp[1] - mouseOffset[1] - bound.y,
//       mp[0] - mouseOffset[0] - bound.x
//     ];
//     this.setState({ handlers: myStateHandlers }, () => {
//       this.callback();
//     });
//     this.handleBarPos(e, handlerId, bound);
//     this.showCleanedPath();
//
//
//
//   }
//
//   if (activeHandlebar) {
//     this.props.drawerChange();
//     activeHandlebar.style.top = e.clientY - mouseOffset[1] - bound.y + "px";
//     activeHandlebar.style.left = e.clientX - mouseOffset[0] - bound.x + "px";
//
//     let xx =
//       activeHandlebar.getBoundingClientRect().left -
//       activeHandler.getBoundingClientRect().left;
//     let yy =
//       activeHandlebar.getBoundingClientRect().top -
//       activeHandler.getBoundingClientRect().top;
//
//     let id = parseInt(activeHandler.id);
//     path.segments[id][activeHandlebar.id].x = xx;
//     path.segments[id][activeHandlebar.id].y = yy;
//     this.handleBarPos(activeHandler, parseInt(activeHandler.id), bound);
//   }
//
//
// }

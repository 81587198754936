import React, { Component } from "react";
import "./howTo.css";
import axios from "axios";
import connectUsb from '../assets/connectUsb.svg'
import admitMic from '../assets/admitMic.svg'
import letsGo from '../assets/letsGo.svg'


let mediaEnabled, selectedDevice, deviceName;


const API_URL = "";

class Howto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      browserIsChrome: 1,
      shower: this.props.showagain,
      showagain: this.props.showagain,
    };

    this.hideMe = this.hideMe.bind(this);
    this.showMe = this.showMe.bind(this);
    this.changeHowTo = this.changeHowTo.bind(this);
    this.checkConnection = this.checkConnection.bind(this)
  }

  componentDidMount() {

  }

  // 'DualSense Wireless Controller'

  checkConnection(){
    let browserIsChrome = navigator.userAgent.indexOf("Chrome")!==-1;
    // this.setState({browserIsChrome: browserIsChrome})
    if(browserIsChrome){

    (async () => {
    await navigator.mediaDevices.getUserMedia({audio: true}).then(()=>{mediaEnabled=true;}).catch(()=>{mediaEnabled=false;});
      await navigator.mediaDevices.enumerateDevices().then((deviceList)=>{

        let audioOut = deviceList.filter(device => device.kind === "audiooutput")
        
        let myLabeledDevs = audioOut.filter(device => device.label.includes("PulseCoreDev") || device.label.includes("DualSense Wireless Controller"))
        let device = myLabeledDevs.filter(
          device =>
          device.label.includes("PulseCoreDev")
          //  &&
          //   device.kind === "audiooutput"
          // //device.deviceId === "default" && device.kind === "audiooutput"
        );
        if (device.length === 0) {
          device = myLabeledDevs.filter(
          device =>
          device.label.includes("DualSense Wireless Controller")
          )
          if(device.length === 0) {
            selectedDevice = false;
            deviceName = 'Audio Output'
          }else{
          selectedDevice = device;
          deviceName = 'DualSense Controller'
        }
        } else {
          selectedDevice = device;
          deviceName = 'pulseDesigner Devkit'
        }

        // let browserIsChrome = navigator.userAgent.indexOf("Chrome")!==-1;
        if(mediaEnabled && selectedDevice){
          this.setState({browserIsChrome:2})
        }else{
          // console.log(mediaEnabled);
          if(!mediaEnabled){
              this.setState({browserIsChrome:4})
          }else if(!selectedDevice){
              if(this.state.browserIsChrome!== 3){
              this.setState({browserIsChrome:3})}else{
                this.setState({browserIsChrome:5})
              }
          }
        }
        // console.log({browserIsChrome,mediaEnabled,selectedDevice});

      })
      .catch(()=>{console.log('fehler');});
      })();
    }else{
      // console.log('otherbrowser');
    }
  }

  hideMe() {
    this.setState({ shower: false });
    this.props.setUp(selectedDevice, deviceName)
  }
  showMe() {
    this.setState({ shower: true });
  }

  changeHowTo(e) {
    // console.log(e.target.checked);
    let checker = e.target.checked;
    axios
      .get(
        API_URL +
          "updateHowTo?uid=" +
          this.props.usr +
          "&myhowto=" +
          e.target.checked
      )
      .then(response => {
        // console.log(response.data);
        //document.getElementById("masterElement").style.opacity = 0;
        this.setState({ showagain: checker });
      })
      .catch(err => {
        console.log("Fehler: ", err);
      });
  }

  render() {
    // console.log(navigator.userAgent.indexOf("Chrome")!==-1);
    let browserIsChrome = navigator.userAgent.indexOf("Chrome")!==-1
    let chromeContent ={
      step1:
      <div className="howTo" id="howTo" ref="howTo">
        <div className="billBoard">
          <h2 className="howtoHeader">Set up the pulse designer step by step</h2>
          <div className="step">
            <div className="explainer">
              <p>1. Connect the pulse Core Devkit via usb with to your computer</p><small>Also the sony DualSense Controller can be conneted via usb</small>
            </div>
              <div className="connectImage">
                  <object type="image/svg+xml" data={connectUsb}>svg-animation</object>
              </div>
          </div>
            <div className="step">
            <div className="explainer">
            <p>2. Check connection</p><small>(Your Browser may ask for permisssion to use the Microphone. Please admit it.).</small>
            </div>

            </div>
            <div className="buttonBar">
            <button onClick={this.checkConnection}>Check</button>
            </div>
        </div>
      </div>
      ,
      step2:
      <div className="howTo" id="howTo" ref="howTo">
        <div className="billBoard">
          <h2 className="howtoHeader">Set up the pulse designer step by step</h2>
          <div className="step">
            <div className="explainer">
              <p>{deviceName} is connected. You can now use the pulseDesigner</p>
            </div>
              <div className="connectImage">
                  <object type="image/svg+xml" data={letsGo}>svg-animation</object>
              </div>
          </div>
            <div className="step">
            <div className="explainer">
            <p></p><small></small>
            </div>

            </div>
            <div className="buttonBar">
            <button onClick={this.hideMe}>Start</button>
            </div>
        </div>
      </div>,
      step3:
      <div className="howTo" id="howTo" ref="howTo">
        <div className="billBoard">
          <h2 className="howtoHeader">Set up the pulse designer step by step</h2>
          <div className="step">
            <div className="explainer">
              <p>pulseDesigner Devkit could not be detected. Please connect it.</p>
            </div>
              <div className="connectImage">
                  <object type="image/svg+xml" data={connectUsb}>svg-animation</object>
              </div>
          </div>
            <div className="step">
            <div className="explainer">
            <p>Check connection again</p><small></small>
            </div>

            </div>
            <div className="buttonBar">
            <button onClick={this.checkConnection}>Check</button>
            </div>
        </div>
      </div>,
      step4:
      <div className="howTo" id="howTo" ref="howTo">
        <div className="billBoard">
          <h2 className="howtoHeader">Set up the pulse designer step by step</h2>
          <div className="step">
            <div className="explainer">
              <p>Permission for Microphone usage denied. Please change your Browser-Settings</p>
            </div>
              <div className="connectImage">
                  <object type="image/svg+xml" data={admitMic}>svg-animation</object>
              </div>
          </div>
            <div className="step">
            <div className="explainer">
            <p>Check connection again</p><small>(Your Browser may ask for permisssion to use the Microphone. Please admit it.).</small>
            </div>

            </div>
            <div className="buttonBar">
            <button onClick={this.checkConnection}>Check</button>
            </div>
        </div>
      </div>,
      step5:
      <div className="howTo" id="howTo" ref="howTo">
        <div className="billBoard">
          <h2 className="howtoHeader">Set up the pulse designer step by step</h2>
          <div className="step">
            <div className="explainer">
              <p>pulseDesigner Devkit could not be detected. Please connect it.</p>
            </div>
              <div className="connectImage">
                  <object type="image/svg+xml" data={connectUsb}>svg-animation</object>
              </div>
          </div>
          <div className="step">
            <div className="explainer">
              <p>Check connection again</p><small></small>
            </div>
          </div>
          <div className="buttonBar">
            <button onClick={this.checkConnection}>Check</button>
          </div>
          <div className="step">
            <div className="explainer">
              <p>Alternatively:</p>
              <p>1. Connect Devkit via usb with to your computer</p>
              <p>2. Open your computer's audio system
              preferences and select the pulse core dev kit as audio-output
              device.</p>
            </div>
          </div>
          <div className="buttonBar">
            <button onClick={this.hideMe}>OK, got it</button>
          </div>
        </div>
      </div>

    }

    if (this.state.shower) {
      // console.log(browserIsChrome);
      if(browserIsChrome){
        return (
          chromeContent['step'+this.state.browserIsChrome]
        );
      }else{
      return (
        <div className="howTo" id="howTo" ref="howTo">
          <div className="billBoard">
            <h2 className="howtoHeader">Set up the pulse designer step by step</h2>
            <div className="step">
              <div className="explainer">
                <p>1. Connect Devkit via usb with to your computer</p>
              </div>
              <div className="connectImage">
                    <object type="image/svg+xml" data={connectUsb}>svg-animation</object>
              </div>
            </div>
            <div className="step">
              <div className="explainer">
                <p>
                  2. Open your computer's audio system
                  preferences and select the pulse core dev kit as audio-output
                  device.
                </p>
              </div>
            </div>
            <div className="buttonBar">
              <button onClick={this.hideMe}>Ok, got it</button>
            </div>
          </div>
        </div>
      );
      }
    } else {
      return (
        <div className="showButton" onClick={this.showMe}>
          <small>?</small>
        </div>
      );
    }
  }
}

export default Howto;

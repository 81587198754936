import React, { Component } from "react";
import "./master-element.css";
import Filecontainer from "./file_container";
import InputSelect from "./input_select";
import GridElement from "./grid_element";
import Drawer from "./drawer";
import Saveconsole from "./saveConsole";
import Howto from "./howTo";
import InputRadio from "./input_radio"


import _ from "lodash";
import { interpolateArray } from "../req/filebuffer";
import axios from "axios";
const API_URL = "";
//http://localhost:9000/
const keyArrays = require("../req/keyarrays");
let player = require("../req/playSound");
let typeArray = ["sinus", "rect", "saw", "triangle"];
let mousePos = [];
let myPlayer, mySource, timer, timeUpdateInterval;
let timePlayed = 0;
let globalVolumeIndex = 9;




class MasterElement extends Component {

  
  constructor(props) {
    super(props);

    this.state = {
      audioChannel:false,
      deviceName: '',
      channelType: 'Both',
      channelIndex: 0,
      channelNum: null,
      channelLabels: [['Both', 'Left', 'Right'],['All', 'Front', 'FrontLeft', 'FrontRight', 'Back', 'BackLeft', 'BackRight']],
      channelContent: "",
      type: 1,
      volume:100,
      drawerCurve: [],
      fileNameToSave: "",
      pressedKey: "none",
      manualHz:undefined,
      flip:{h:false, v:false},
      canvasBound:[],
      mousePos:[],
      mouseDown: false,
      mouseTracking:false,
      isplaying:false,

      content: {
        flipv:false,
        fliph:false,
        file: { leftTopT: undefined, rightTopT: undefined },
        fileBuffer: { leftTopT: undefined, rightTopT: undefined },
        filetime: [undefined, undefined],
        columns: 1,
        transformation: "Frameperframe",
        rows: 3,
        leftTopHz: 50,
        rightTopHz: 70,
        leftTopT: "sinus",
        rightTopT: "rect",
        leftTopD: 0.1,
        rightTopD: 0.1,
        durations: { leftTopT: 0.1, rightTopT: 0.1 },
        envelopLeft: [
          { left: -12, top: -12 },
          { left: 88, top: -12 },
          { left: 188, top: -12 },
          { left: 288, top: -12 },
          { left: 388, top: -12 },
          { left: 488, top: -12 },
          "left"
        ],
        envelopRight: [
          { left: -4, top: -4 },
          { left: 21, top: -4 },
          { left: 46, top: -4 },
          { left: 71, top: -4 },
          { left: 96, top: -4 },
          "right"
        ],
        envelopBases: { envelopLeft: 0, envelopRight: 0 },
        envelopArrays: { left: undefined, right: undefined },
        tempValues: {
          primitives: {
            d: 0.1,
            t: "sinus",
            f: 50,
            e: [
              { left: -12, top: -12 },
              { left: 88, top: -12 },
              { left: 188, top: -12 },
              { left: 288, top: -12 },
              { left: 388, top: -12 },
              { left: 488, top: -12 },
              "left"
            ],
            eb: 0,
            ea: undefined,
            sf: ""
          },
          files: {
            d: 0.1,
            file: undefined,
            fileBuffer: undefined,
            e: [
              { left: -12, top: -12 },
              { left: 88, top: -12 },
              { left: 188, top: -12 },
              { left: 288, top: -12 },
              { left: 388, top: -12 },
              { left: 488, top: -12 },
              "left"
            ],
            eb: 0,
            ea: undefined,
            sf: ""
          },
          draws: {
            d: 0.1,
            sf: ""
          }
        }
      },
      database: [],
      databaseSound: [],
      saveValue: "",
      embeddedSound: [],
      saveMode: false,
      dataToSave: {},
      updateContent: false,
      ownEffectActive: false,
      loop: false
      //embeddedSounds:{l:undefined,}
    };
    this.change = this.change.bind(this);
    this.getFileInfo = this.getFileInfo.bind(this);
    this.startDragHandler = this.startDragHandler.bind(this);
    this.dragDragHandler = this.dragDragHandler.bind(this);
    this.stopDragHandler = this.stopDragHandler.bind(this);
    this.handleConsoleChange = this.handleConsoleChange.bind(this);
    this.handleConsoleSubmit = this.handleConsoleSubmit.bind(this);
    this.embedSound = this.embedSound.bind(this);
    this.fileButtonClick = this.fileButtonClick.bind(this);
    this.inputClick = this.inputClick.bind(this);
    this.rangeChange = this.rangeChange.bind(this);
    this.getSound = this.getSound.bind(this);
    this.getDrawerData = this.getDrawerData.bind(this);
    this.saveSound = this.saveSound.bind(this);
    this.downloadSound = this.downloadSound.bind(this);
    this.closeSaveConsole = this.closeSaveConsole.bind(this);
    this.loadEffect = this.loadEffect.bind(this);
    this.switchToDownloadButton = this.switchToDownloadButton.bind(this);
    this.mylogout = this.mylogout.bind(this);
    this.drawerChange = this.drawerChange.bind(this);
    this.keyDownChecker = this.keyDownChecker.bind(this);
    this.keypresser = this.keypresser.bind(this);
    this.keyupper = this.keyupper.bind(this);
    this.openSaveBurger = this.openSaveBurger.bind(this);
    this.selectSaveBurgerItem = this.selectSaveBurgerItem.bind(this);
    this.setLoop = this.setLoop.bind(this);
    this.stopSound = this.stopSound.bind(this);
    this.setConnection = this.setConnection.bind(this);
    this.volumeChange = this.volumeChange.bind(this);
    this.changeT = this.changeT.bind(this)
    this.changeChannel = this.changeChannel.bind(this);
    this.changelabel = this.changelabel.bind(this);
    this.flipCurve = this.flipCurve.bind(this);
    this.canvasBound = this.canvasBound.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.checkChannels = this.checkChannels.bind(this)
  }


mouseDown(){
  this.setState({mouseDown:true})
}

mouseUp(){
  this.setState({mouseDown:false})


  this.refs['drawer'].pathStop()
}


  flipCurve(e){

    // console.log('flip');

this.refs["fileContainer"].deselect("")
    let n = e.target.getAttribute('name');

    n = n.slice(n.length-1, n.length).toLowerCase();
    let myContent = this.state.content;
    let myDrawerCurve = this.state.drawerCurve;
    let myFlip = this.state.flip;

    if(myContent['flip'+n]){
      myContent['flip'+n]=false
    }else{
      myContent['flip'+n]=true
    }

    myFlip[n] = myContent['flip'+n]



    if(myContent.fileBuffer.leftTopT && this.state.type==="2"){
       if(n==='v'){
          for(let b = 0; b < myContent.fileBuffer.leftTopT.length; b++){
            myContent.fileBuffer.leftTopT[b] = myContent.fileBuffer.leftTopT[b]*-1
          }
       }
       if(n==='h'){
          myContent.fileBuffer.leftTopT=myContent.fileBuffer.leftTopT.reverse();
      }
    }

    if(this.state.type==="3"){


      this.refs["drawer"].flipCurve(n);

       if(n==='v'){


         for(let b = 0; b < myDrawerCurve.bufferCurve.length; b++){
           myDrawerCurve.bufferCurve[b] =myDrawerCurve.bufferCurve[b]*-1;
           // myDrawerCurve.segments[b].point.y = myContent.fileBuffer.leftTopT[b]*-1
         }

       }

       if(n==='h'){
           myDrawerCurve.bufferCurve =myDrawerCurve.bufferCurve.reverse();
      }

    }



    if(n==='h'){
      let newe = myContent.envelopLeft.slice(0,6);

      for(let i = 0; i<newe.length;i++){
         newe[i]= {left: 500-myContent.envelopLeft[newe.length-i-1].left-24 , top: myContent.envelopLeft[newe.length-i-1].top};

      }



        myContent.envelopLeft = newe;

        myContent.envelopArrays.left = keyArrays.envArray(
          myContent.envelopLeft.slice(0, 6)
        );

    }
    this.setState(
      {
        flip: myFlip,
        drawerCurve: myDrawerCurve,
        content:myContent,
      },
      () => {
        this.callback();
      }
    );



  }

 checkChannels(){setInterval(()=>{
  let myChannelNum = player.checkMaxChannelNum();
  let myChannelContent = <InputRadio id='channelSelector' myRange={this.state.channelLabels[0]} myActive={this.state.channelIndex} changeChannel={this.changeChannel} myDevice={this.state.deviceName}/>;
  // console.log('max. channels: ' + this.state.channelNum, this.state.channelLabels)
  if(myChannelNum !== this.state.channelNum){
    // let myChannelType

    if (myChannelNum === 2){
      // myChannelLabel = ['Both', 'Left', 'Right']
      // myChannelType = 'Both';
      // myChannelIndex = 0;
      myChannelContent = <InputRadio id='channelSelector' myRange={this.state.channelLabels[0]} myActive={this.state.channelIndex} changeChannel={this.changeChannel} myDevice={this.state.deviceName}/>
      myChannelNum = 2;
    }else{
      // myChannelLabel = ['All', 'Front', 'FrontLeft', 'FrontRight', 'Back', 'BackLeft', 'BackRight']
      // myChannelType = 'All';
      // myChannelIndex = 0;
      myChannelContent = <InputRadio id='channelSelector' myRange={this.state.channelLabels[1]} myActive={this.state.channelIndex} changeChannel={this.changeChannel} myDevice={this.state.deviceName}/>
      myChannelNum = 4;
    }

  }

  if(this.state.deviceName === 'DualSense Controller' && myChannelNum >2){

    myChannelNum = 2;
    myChannelContent = <InputRadio id='channelSelector' myRange={this.state.channelLabels[0]} myActive={this.state.channelIndex} changeChannel={this.changeChannel} myDevice={this.state.deviceName}/>

  }


  this.setState({
    // channelIndex:myChannelIndex,
    channelNum: myChannelNum,
    channelContent: myChannelContent
    // channelType: myChannelType,
  })


}, 1500)};

 

// function checkChannelsHandler(){
//   player.checkMaxChannelNum()
// }

  changeChannel(c){
    
    let channelArray = this.state.channelNum === 2 ? this.state.channelLabels[0] : this.state.channelLabels[1];
    // console.log('channel: ',channelArray.findIndex(cc => cc === c), c)
    console.log(channelArray, c)
    this.setState(
      {
        channelType:c,
        channelIndex: channelArray.findIndex(cc => cc === c),
      },
      () => {
        this.callback();
      }
    );

  }


  setConnection(c,dn){
  
    this.setState({audioChannel:c, deviceName: dn})
  }

  setLoop(e) {
    if (e.target.classList.length === 2) {
      e.target.classList.add("loopButtonActive");
    } else {
      e.target.classList.remove("loopButtonActive");
      if (myPlayer) {
        this.stopSound();
      }
      if (this.refs["playButton"].classList.value.includes("stopButton")) {
        this.refs["playButton"].classList.remove("stopButton");
        this.refs["playButton"].classList.add("playButton");
      }

      // this.refs["playButton"].classList.remove("stopButton"),
      //   this.refs["playButton"].classList.add("playButton");
    }
    this.setState({
      loop: e.target.classList.value.includes("loopButtonActive")
    });
  }

  openSaveBurger(e) {
    // this.stopSound();
    let menu = this.refs["saveBurgerMenu"];
    // this.refs["saveBurgerMenu"].style.display = "flex";
    if (menu.style.display === "none") {
      menu.style.display = "flex";
      e.target.classList.add("closeSaveBurger");
    } else {
      menu.style.display = "none";
      e.target.classList.remove("closeSaveBurger");
    }
  }

  selectSaveBurgerItem(e) {
    this.stopSound();
    let cl = e.target.classList.value
      .split(" ")
      .includes("s");

    if (!cl) {
      this.refs["saveBurgerMenu"].style.display = "none";
      this.refs["saveBurger"].classList.remove("closeSaveBurger");
      let id = e.target.id.substring(10);

      switch (id) {
        case "Save":
          break;
        case "SaveAs":
          this.saveSound();
          break;
        case "Download":
          this.downloadSound();
          break;
        case "Delete":

          let isdeleted = this.refs["fileContainer"].deleteEffect();
          if (isdeleted !== false) {
            // this.refs["burgerMenuDelete"].classList.add(
            //   "saveBurgerDisabledMenuButton"
            // );
            this.refs["burgerMenuDelete"].disabled = true;
          }
          break;
        default:
      }
    }
  }

  keyDownChecker(key, e) {
    this.setState({ pressedKey: key }, () => {
      this.callback();
    });
  }

  keypresser(e) {
    this.setState({ pressedKey: e.key }, () => {
      this.callback();
    });
  }

  keyupper() {
    this.setState({ pressedKey: "none" }, () => {
      this.callback();
    });
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypresser);
    document.addEventListener("keyup", this.keyupper);
    let label = this.refs["volumeLabel"];
    for(let c = 0; c < label.children.length; c++){
      label.children[c].style.opacity = 0;
    }
    this.refs['volume3'].style.opacity = 1;
    this.refs["burgerMenuDelete"].disabled = true;
    this.checkChannels();
    let myChannelType = this.state.channelNum === 2 ? this.state.channelLabels[0][0] : this.state.channelLabels[1][0];
    this.setState({channelType: myChannelType})
  }



  mylogout() {
    this.refs["fileContainer"].mylogout();
  }

  currentInput;

  drawerChange(d) {
    if(d){
      this.refs["fileContainer"].deselect("");
    }
    // console.log('drawerchange: ', d);
    this.stopSound();

    let newContent = this.state.content;

    newContent.tempValues.draws.d = newContent.leftTopD;
    newContent.tempValues.primitives.file = "";

    this.setState(
      {
        content: newContent
      },
      () => {
        this.callback();
      }
    );
  }

  changeT(e){
    if(e.target.value.toString().length>3 || parseInt(e.target.value.length)>300){
      alert('Value should not be bigger than 300')
      e.target.value = 300
    }
    this.setState({manualHz:e.target.value})
    this.change(e)
  }

  change(e) {
    this.refs["fileContainer"].deselect("");
    this.stopSound();
    this.currentInput = e.target.name;
    let newContent = this.state.content;
    let newValue;

    if (Number(e.target.value)) {
      newValue = Number(e.target.value);
    } else if (typeArray.includes(e.target.value)) {
      newValue = e.target.value;

      if (newContent.file.leftTopT !== undefined) {
        newContent.leftTopD = 0.1;
      }
    }

    newContent[e.target.name] = newValue;
    // newContent.file.leftTopT = undefined;
    // newContent.fileBuffer.leftTopT = undefined;

    if (e.target.name === "leftTopT") {
      newContent.filetime[0] = undefined;

      //  ;
      newContent.envelopBases.envelopLeft = 0;

      let embedded = this.state.databaseSound.find(myset => {
        return myset.soundName === e.target.value;
      });
      if (embedded) {
        //setAttribute("disabled", "disabled")
        newContent.envelopBases.envelopLeft = embedded.envBase;
        newContent.leftTopD = embedded.duration;
        newContent.filetime[0] = embedded.duration;
        newContent.file.leftTopT = newValue;
      }
    }
    switch (parseInt(this.state.type)) {
      case 1:
        newContent.tempValues.primitives.d = newContent.leftTopD;
        newContent.tempValues.primitives.t = newContent.leftTopT;
        newContent.tempValues.primitives.f = newContent.leftTopHz;
        newContent.tempValues.primitives.e = newContent.envelopLeft;
        newContent.tempValues.primitives.ea = newContent.envelopArrays.left;
        newContent.tempValues.primitives.eb =
          newContent.envelopBases.envelopLeft;
        newContent.tempValues.primitives.sf = "";
        break;
      case 2:
        newContent.tempValues.files.d = newContent.leftTopD;
        newContent.tempValues.files.file = newContent.file.leftTopT;
        newContent.tempValues.files.fileBuffer = newContent.fileBuffer.leftTopT;
        newContent.tempValues.files.e = newContent.envelopLeft;
        newContent.tempValues.files.ea = newContent.envelopArrays.left;
        newContent.tempValues.files.eb = newContent.envelopBases.envelopLeft;
        newContent.tempValues.files.sf = "";
        break;
      case 3:
        newContent.tempValues.draws.d = newContent.leftTopD;
        newContent.tempValues.draws.sf = "";
        break;
        default:
    }

    this.setState(
      {
        fileNameToSave: "",
        content: newContent
      },
      () => {
        this.callback();
      }
    );

    if (newValue === "file") {
      document.getElementById("myFileInput").click();
    }
  }

  rangeChange(e) {
    let t;
    t = this.refs["ranger"].value;
    let mc = this.state.content;

    this.setState({ type: t }, () => {
      this.callback();
    });

    document.getElementById("rangeCurve").classList.remove("rangeActive");
    document.getElementById("rangeFile").classList.remove("rangeActive");
    document.getElementById("rangeShape").classList.remove("rangeActive");
    document.getElementById("curveSub").classList.remove("activeSub");
    document.getElementById("fileSub").classList.remove("activeSub");
    document.getElementById("shapeSub").classList.remove("activeSub");

    switch (t) {
      case "1":
      this.setState({mouseTracking:false})
        document.getElementById("rangeCurve").classList.add("rangeActive");
        document.getElementById("curveSub").style.marginLeft = "0px";
        document.getElementById("curveSub").classList.add("activeSub");

        document.getElementById("drawerBox").style.display = "none";
        document.getElementById("gridelements").style.display = "inline";

        mc.leftTopD = mc.tempValues.primitives.d;
        mc.leftTopT = mc.tempValues.primitives.t;
        mc.leftTopHz = mc.tempValues.primitives.f;
        mc.envelopLeft = mc.tempValues.primitives.e;
        mc.envelopBases.envelopLeft = mc.tempValues.primitives.eb;
        //  mc.envelopArrays.left = mc.tempValues.primitives.ea;

        mc.envelopArrays.left = keyArrays.envArray(
          mc.tempValues.primitives.e.slice(0, 6)
        );

        this.setState({ content: mc }, () => {
          this.callback();
        });
        this.refs["drawer"].posTools(36.25);
        this.refs["fileContainer"].deselect(mc.tempValues.primitives.sf);
        break;
      case "2":
      this.setState({mouseTracking:false})
        document.getElementById("rangeFile").classList.add("rangeActive");
        document.getElementById("curveSub").style.marginLeft = "-21.75rem";
        document.getElementById("fileSub").classList.add("activeSub");

        document.getElementById("drawerBox").style.display = "none";
        document.getElementById("gridelements").style.display = "inline";
        mc.leftTopT = "file";
        mc.leftTopD = mc.tempValues.files.d;
        mc.file.leftTopT = mc.tempValues.files.file;
        mc.fileBuffer.leftTopT = mc.tempValues.files.fileBuffer;
        mc.envelopLeft = mc.tempValues.files.e;
        mc.envelopBases.envelopLeft = mc.tempValues.files.eb;
        //  mc.envelopArrays.left = mc.tempValues.files.ea;

        mc.envelopArrays.left = keyArrays.envArray(
          mc.tempValues.files.e.slice(0, 6)
        );

        this.setState({ content: mc }, () => {
          this.callback();
        });

        if (e && !this.state.content.fileBuffer.leftTopT) {
          this.fileButtonClick();
        }
        this.refs["drawer"].posTools(14.5);
        this.refs["fileContainer"].deselect(mc.tempValues.files.sf);
        break;
      case "3":
        document.getElementById("rangeShape").classList.add("rangeActive");
        document.getElementById("curveSub").style.marginLeft = "-36.25rem";
        document.getElementById("shapeSub").classList.add("activeSub");

        document.getElementById("drawerBox").style.display = "inline";
        document.getElementById("gridelements").style.display = "none";

        this.refs["drawer"].posTools(0);

        // setTimeout(
        //
        //
        //     this.setState({mouseTracking:true})
        //   ,
        //   20000
        // );

        mc.leftTopD = mc.tempValues.draws.d;

        this.setState({ content: mc }, () => {
          this.callback();
        });
        this.refs["fileContainer"].deselect(mc.tempValues.draws.sf);
        break;
      default:
        this.setState({mouseTracking:false})
      ///
    }
    this.stopSound();
  }

  volumeChange(e) {
      let t = this.refs["volumeRanger"].value;


    let slider = this.refs["volumeControlSlider"];
    let value = this.refs["volumeControlValue"];

    slider.style.top =  value.style.top = 84 - ((t/100)*64) +"px"
    value.style.height = ((t/100)*64)+16 +"px";
    // let mc = this.state.content;
    //
    this.setState({ volume: t }, () => {
      this.callback();
    });


  }

  changelabel(){

    let t,i;
    t = this.refs["volumeRanger"].value;
    let label = this.refs["volumeLabel"];






    let labelIndex = parseInt(t/33);

    if(t> 0){
       if(labelIndex  !== globalVolumeIndex){

         i = labelIndex;
         globalVolumeIndex = i;
         for(let c = 0; c < label.children.length; c++){
           label.children[c].style.opacity = 0;
         }
         this.refs['volume'+i].style.opacity = 1;
       }
     }else{


       for(let c = 0; c < label.children.length; c++){
         label.children[c].style.opacity = 0;
       }
       i=0
        globalVolumeIndex = -1;

     }

     ;


  }

  fileButtonClick(e) {
    //leftTopT
    this.currentInput = "leftTopT";
    let mc = this.state.content;
    mc["leftTopT"] = "file";

    document.getElementById("myFileInput").click();
    this.setState({ content: mc }, () => {
      this.callback();
    });
  }

  callback() {
    let myValue = this.state.content.leftTopD;

    if (myValue.toString().length > 3) {
      myValue = myValue.toFixed(2);
    } else {
      myValue = myValue.toFixed(1);
    }
    myValue.toString();

    for (let i = 1; i <= this.state.content.columns; i++) {
      this.refs["GridElement" + i].getCurve();
    }

    if(myPlayer){
      myPlayer.volume = parseFloat(this.state.volume/100)
    }

    // let mcn = player.checkMaxChannelNum();
    // this.setState({channelNum : mcn})
    
    console.log(this.state.channelType)
    this.changelabel();
  }


  getFileInfo(e) {
    if (e.target.files[0]) {
      let myFiles = this.state.content;
      myFiles.file.leftTopT = undefined;
      myFiles.file.rightTopT = undefined;
      myFiles.file[this.currentInput] = e.target.files[0];
      myFiles.tempValues.files.file = e.target.files[0];

      this.setState({ content: myFiles });
      let file = e.target.files[0];
      let reader = new FileReader();
      let that = this;
      reader.onload = function(event) {
        let audioCtx = new AudioContext();

        audioCtx.decodeAudioData(event.target.result).then(function(buffer) {
          let dd = buffer.length / audioCtx.sampleRate;
          if (dd > 3) {
            return alert("Audiofile duration must be <= 3sec");
          }
          let finalBuffer = interpolateArray(buffer.getChannelData(0), 50000);

          let cc = _.zipWith(finalBuffer, finalBuffer => {
            return finalBuffer * -1;
          });

          let newContent = that.state.content;
          newContent.fileBuffer[that.currentInput] = cc;
          newContent.tempValues.files.fileBuffer = cc;
          let envBorder = Math.max(
            Math.abs(Math.min(...newContent.fileBuffer[that.currentInput])),
            Math.max(...newContent.fileBuffer[that.currentInput])
          );

          envBorder = 250 - envBorder * 250;

          //let envBorder = 1;
          let envDirection;
          if (that.currentInput === "leftTopT") {
            newContent.leftTopD = dd;
            newContent.filetime[0] = dd;
            newContent.tempValues.files.d = dd;
            envDirection = "envelopLeft";
          } else {
            newContent.rightTopD = dd;
            newContent.filetime[1] = dd;
            newContent.tempValues.files.d = dd;
            envDirection = "envelopRight";
          }
          for (let i = 0; i <= newContent[envDirection].length - 1; i++) {
            let xx = newContent[envDirection][i].left;
            newContent[envDirection][i] = { left: xx, top: envBorder - 4 };

            newContent.envelopBases[envDirection] = envBorder - 4;
          }
          newContent.envelopArrays.left = keyArrays.envArray(
            newContent.envelopLeft.slice(0, 6)
          );

          newContent.tempValues.files.eb = newContent.envelopBases.envelopLeft;

          that.setState({ content: newContent }, () => {
            that.callback();
          });
          //that.embedSound(that.currentInput, soundName, dd);
        });
      };

      reader.readAsArrayBuffer(file);
    }

  }

  embedSound(side, name, duration) {
    let body = {};
    body.soundName = name;
    body.fileBuffer = this.state.content.fileBuffer[side];
    body.duration = duration;
    if (side === "leftTopT") {
      body.envBase = this.state.content.envelopBases.envelopLeft;
    } else {
      body.envBase = this.state.content.envelopBases.envelopRight;
    }
  }

  canvasBound(canvasBound){

    this.setState({ canvasBound:canvasBound }, () => {
      this.callback();
    });
  }

  getDrawerData(myCurve) {

    this.setState({ drawerCurve: myCurve}, () => {
      this.callback();
    });
  }

  getSound(e) {
    if(this.state.isplaying){this.setState({isplaying:false})}else{this.setState({isplaying:true})}
    if (!this.state.isplaying) {
      e.target.classList.remove("playButton");
      e.target.classList.add("stopButton");

      let mc;
      if (parseInt(this.state.type) < 3) {
        mc = this.refs["GridElement1"].props.myCurve;
      } else {
        mc = this.state.drawerCurve.bufferCurve;
      }
      // console.log(mc.length);
      
  

      mySource = player.playSource(
        mc,
        this.state.content.leftTopD,
        this.state.loop,
        this.state.audioChannel,
        this.state.volume,
        this.state.channelType,
        false,
        this.state.deviceName,
        this.state.volume/100.0)

      let d = this.state.content.leftTopD;
      let startTime;
      timePlayed = 0;
      let that = this;
      
      const updateNeedle = ()=>{
        let actualTime = new Date().getTime()
        timePlayed = actualTime-startTime;
        let totalTime = d*1000;
        // console.log(timeUpdateInterval)
        if(timePlayed > d*1000){
          clearInterval(timeUpdateInterval);
          timeUpdateInterval = null;
          timePlayed = 0;
          that.refs["needle"].style.display = "none";
        }else{
          let needlepos = (timePlayed / totalTime) * 500;

          if (needlepos === 0 || needlepos === 500) {
            that.refs["needle"].style.display = "none";
          } else {
            that.refs["needle"].style.display = "inline";
          }
          that.refs["needle"].style.left = needlepos + "px";
          that.refs["needle"].style.opacity = needlepos;
        }
       
      }

      const sourceStart = ()=>{
        
        mySource.start();
        startTime = new Date().getTime();
        timePlayed = 0;
        if(!timeUpdateInterval){
        timeUpdateInterval = setInterval(updateNeedle, 100);
        }
      }

      if (this.state.loop) {
        sourceStart()
        timer = setInterval(() => {
          mySource = player.playSource(
            mc,
            this.state.content.leftTopD,
            this.state.loop,
            this.state.audioChannel,
            this.state.volume,
            this.state.channelType,
            false,
            this.state.deviceName,
            this.state.volume/100.0)
            mySource.start();
            startTime = new Date().getTime();
            timePlayed = 0;
            if(!timeUpdateInterval){
              timeUpdateInterval = setInterval(updateNeedle, 100);
            }
        }, (d + 1) * 1000);
      } else {
        sourceStart();
      }

      mySource.onended = () => {
        // clearInterval(timeUpdateInterval);
        // timeUpdateInterval = 0;
        if (this.state.loop) {
          
        } else {
          if (this.refs["playButton"].classList.value.includes("stopButton")) {
            this.refs["playButton"].classList.add("playButton");
            this.refs["playButton"].classList.remove("stopButton");
          }
          // mySource.pause();
          // mySource.currentTime = 0;
          this.setState({isplaying:false})
          return "end";
        }
        
        
        
      };
    this.setState({isplaying:true})
    } else {
      console.log("stopped")
      mySource.stop();

      clearInterval(timer);
      timePlayed = 0;
      clearInterval(timeUpdateInterval);
      timeUpdateInterval = 0;
      this.refs["needle"].style.display = "none";
      e.target.classList.add("playButton");
      e.target.classList.remove("stopButton");
      this.setState({isplaying:false})
    }
  }

  saveSound(e) {
    document.getElementById("saveConsoleWrapper").style.display = "inline";

    let mc;
    if (parseInt(this.state.type) < 3) {
      mc = this.refs["GridElement1"].props.myCurve;
    } else {
      mc = this.state.drawerCurve;
    }


    let mycontent = this.state.dataToSave;

    mycontent = {
      userId: this.props.usr,
      type: this.state.type,
      file: this.state.content.file.leftTopT,
      fileBuffer: this.state.content.fileBuffer.leftTopT,
      curveType: this.state.content.leftTopT,
      curveFrequency: this.state.content.leftTopHz,
      curve: mc,
      duration: this.state.content.leftTopD,
      envBase: this.state.content.envelopBases.envelopLeft,
      envPoints: this.state.content.envelopLeft,
      envCurve: keyArrays.envArray(this.state.content.envelopLeft.slice(0, 6)),
      drawerCurve: this.state.drawerCurve,
      ownEffectActive: this.state.ownEffectActive,
      volume: this.state.volume,
      channel: this.state.channelType,
      flip: this.state.flip
    };



    this.setState({ dataToSave: mycontent }, () => {
      this.callback();
    });
  }

  downloadSound(e) {
    let mc;
    if (parseInt(this.state.type) < 3) {
      mc = this.refs["GridElement1"].props.myCurve;
    } else {
      mc = this.state.drawerCurve.bufferCurve;
    }

    player.downloadSound(
      mc,
      this.state.content.leftTopD,
      this.state.fileNameToSave,
      this.state.channelType
    );
  }

  closeSaveConsole(e, en) {
    if (e) {
      this.setState({ ownEffectActive: true });
      // this.refs["burgerMenuDelete"].classList.remove(
      //   "saveBurgerDisabledMenuButton"
      // );
      this.refs["burgerMenuDelete"].disabled = false;
    }
    this.refs["fileContainer"].createContent(e, en);

    document.getElementById("saveConsoleWrapper").style.display = "none";
    this.switchToDownloadButton(true);
    this.refs["saveConsoleWrapper"].switchToDefaultView();
  }

  activHandler = undefined;
  activBounding = undefined;
  mouseOffset = [];

  stopSound() {
    clearInterval(timer);
    clearInterval(timeUpdateInterval)
    if (mySource) {
      mySource.stop();
      mySource.currentTime = 0;
      if (this.state.isplaying) {
        this.refs["playButton"].classList.remove("stopButton");
        this.refs["playButton"].classList.add("playButton");
      }
    }
    this.refs["burgerMenuDelete"].disabled = true;
    // this.refs["burgerMenuDelete"].classList.add("saveBurgerDisabledMenuButton");
  }

  startDragHandler(e) {
    this.setState({mouseDown:true})
    this.stopSound();
    this.refs["dragModifierHint"].style.opacity = 0.5;
    let myBound = e.target.getBoundingClientRect();
    this.mouseOffset = [e.clientX - myBound.x, e.clientY - myBound.y];
    this.activHandler = e.target;
    this.activBounding = e.target.parentElement;
  }

  dragDragHandler(e) {

    // console.log(this.state.canvasBound);

    if(this.state.canvasBound.length>0){

      if(e.clientX>this.state.canvasBound[0]+5&&e.clientX<this.state.canvasBound[2]-5&&e.clientY>this.state.canvasBound[1]+5&&e.clientY<this.state.canvasBound[3]-5){
        mousePos = [e.clientX, e.clientY];
      }else{
        let newMx, newMy;
        if(e.clientX<this.state.canvasBound[0]+5){
          newMx = this.state.canvasBound[0]+5;
        }else if(e.clientX>this.state.canvasBound[2]-5){
          newMx = this.state.canvasBound[2]-5;
        }else{
          newMx = e.clientX;
        }
        if(e.clientY<this.state.canvasBound[1]+5){
          newMy = this.state.canvasBound[1]+5;
        }else if (e.clientY>this.state.canvasBound[3]-5){
          newMy = this.state.canvasBound[3]-5;
        }else{
          newMy = e.clientY;
        }
        mousePos = [newMx, newMy];

      }
      this.setState({mousePos:mousePos})
    }

    if (this.activHandler) {
      this.refs["fileContainer"].deselect("");
      clearInterval(timer);
      let id = this.activHandler.id;
      let index = Number(id.substring(id.length - 2, id.length));
      let parent = this.activBounding;
      let parentIndex = Number(
        parent.id.substring(parent.id.length - 2, parent.id.length)
      );
      let bounds = parent.getBoundingClientRect();

      let myContent = this.state.content;
      let myEnvelop;

      if (parentIndex === 1) {
        myEnvelop = myContent.envelopLeft;
      } else {
        myEnvelop = myContent.envelopRight;
      }

      //////////

      let xx;
      if (index !== 1 && index !== 6 && this.state.pressedKey === "h") {
        if (
          e.clientX - bounds.x - this.mouseOffset[0] >=
            myEnvelop[index - 2].left &&
          e.clientX - bounds.x - this.mouseOffset[0] <= myEnvelop[index].left
        ) {
          xx = e.clientX - bounds.x - this.mouseOffset[0];
        } else if (
          e.clientX - bounds.x - this.mouseOffset[0] <
          myEnvelop[index - 2].left + 4
        ) {
          xx = myEnvelop[index - 2].left;
        } else {
          xx = myEnvelop[index].left;
        }
      } else {
        xx = myEnvelop[index - 1].left;
      }

      let yy;

      if (
        e.clientY - bounds.y - this.mouseOffset[1] >= -12 &&
        e.clientY - bounds.y - this.mouseOffset[1] <= 238
      ) {
        this.activHandler.children[1].children[0].style.opacity = this.activHandler.children[1].children[2].style.opacity =
          "1.0";
        yy = e.clientY - bounds.y - this.mouseOffset[1];
      } else if (e.clientY - bounds.y - this.mouseOffset[1] < -12) {
        yy = -12;
        this.activHandler.children[1].children[0].style.opacity = "0.3";
      } else {
        yy = 238;
        this.activHandler.children[1].children[2].style.opacity = "0.3";
      }


      if (parentIndex === 1) {
        myContent.envelopLeft[index - 1] = { left: xx, top: yy };
        myContent.envelopArrays.left = keyArrays.envArray(
          myContent.envelopLeft.slice(0, 6)
        );
        myContent.envelopArrays.right = keyArrays.envArray(
          myContent.envelopRight.slice(0, 6)
        );
      } else {
        myContent.envelopRight[index - 1] = { left: xx, top: yy };
        myContent.envelopArrays.right = keyArrays.envArray(
          myContent.envelopRight.slice(0, 5)
        );
        myContent.envelopArrays.left = keyArrays.envArray(
          myContent.envelopLeft.slice(0, 5)
        );
      }

      switch (parseInt(this.state.type)) {
        case 1:
          myContent.tempValues.primitives.sf = "";
          break;
        case 2:
          myContent.tempValues.files.sf = "";
          break;
        case 3:
          myContent.tempValues.primitives.file = "";
          break;
        default:
      }

      this.setState({ fileNameToSave: "", content: myContent }, () => {
        this.callback();
      });
    }


  }

  stopDragHandler(e) {
    this.refs["dragModifierHint"].style.opacity = 0;
    this.activHandler = undefined;
    this.refs['drawer'].pathStop()
    this.setState({mouseDown:false})
    this.refs['drawer'].stopDragHandler();
  }

  handleConsoleChange(event) {
    this.setState({ saveValue: event.target.value });
  }

  handleConsoleSubmit(event) {
    event.preventDefault();
    let body = {};
    let matrixContent = Object.assign({}, this.state.content);
    let fileBufferContent = Object.assign({}, this.state.content.fileBuffer);
    delete matrixContent.fileBuffer;
    delete matrixContent.envelopArrays;

    matrixContent.envelopArrays = { left: undefined, right: undefined };
    matrixContent.fileBuffer = { left: undefined, right: undefined };
    body.matrixContent = JSON.stringify(matrixContent);

    body.fileBufferLeft = fileBufferContent.leftTopT;

    body.matrixName = this.state.saveValue;
    axios
      .post(API_URL + "newMatrix", body)
      .then(response => {
        alert(response.data.message);
      })
      .catch(err => {
        console.log("Fehler: ", err);
      });

    this.refs["fileConsoleContent"].style.width = "0";
    this.refs["fileConsoleButton"].style.display = "block";
    this.refs["fileSaveButton"].style.display = "block";
  }

  inputClick(e) {}

  switchToDownloadButton(download) {
  }

  loadEffect(data, selected, own) {
    // document.getElementById("saveButton").style.display = "none";
    // document.getElementById("downloadButton").style.display = "flex";

    // console.log('loadeffect masterelelemt: ',data.envPoints);

    // console.log(data.fileBuffer);
    let maxChannels = player.checkMaxChannelNum();
    let myStatesContent = this.state.content;

    //myStatesContent.fileNameToSave = data.effectName;
    myStatesContent.file.leftTopT = data.file;
    myStatesContent.fileBuffer.leftTopT = data.fileBuffer;
    myStatesContent.leftTopT = data.curveType;
    myStatesContent.leftTopHz = data.curveFrequency;
    myStatesContent.leftTopD = data.duration;
    myStatesContent.envelopArrays.left = keyArrays.envArray(
      data.envPoints.slice(0, 6)
    );
    myStatesContent.envelopBases.envelopLeft = data.envBase;
    myStatesContent.envelopLeft = data.envPoints;


    switch (data.type) {
      case 1:
        myStatesContent.tempValues.primitives.d = data.duration;
        myStatesContent.tempValues.primitives.t = data.curveType;
        myStatesContent.tempValues.primitives.f = data.curveFrequency;
        myStatesContent.tempValues.primitives.e = data.envPoints;
        myStatesContent.tempValues.primitives.eb = data.envBase;
        myStatesContent.tempValues.primitives.ea = keyArrays.envArray(
          data.envPoints.slice(0, 6)
        );
        myStatesContent.tempValues.primitives.sf = selected;
        break;
      case 2:

        let fileBufferString = Buffer.from(data.fileBuffer.data).toString('utf8');
        let fileBufferData = fileBufferString.split(',')
        _.forEach(fileBufferData, function(value,index) {
        fileBufferData[index] =  parseFloat(fileBufferData[index])});

        myStatesContent.tempValues.files.d = data.duration;
        myStatesContent.tempValues.files.file = data.file;
        myStatesContent.tempValues.files.fileBuffer = fileBufferData;
        myStatesContent.tempValues.files.e = data.envPoints;
        myStatesContent.tempValues.files.eb = data.envBase;
        myStatesContent.tempValues.files.ea = keyArrays.envArray(
          data.envPoints.slice(0, 6)
        );
        myStatesContent.tempValues.files.sf = selected;
        break;

      case 3:
        myStatesContent.tempValues.draws.d = data.duration;

        this.refs["drawer"].loadCurve(data.drawerCurve);

        myStatesContent.tempValues.draws.sf = selected;

        myStatesContent.flip = data.flip;
        if(myStatesContent.flip.h){
          this.refs["drawer"].flipCurve('h')
        }
        if(myStatesContent.flip.v){
          this.refs["drawer"].flipCurve('v')
        }
        break;
      default:
    }

    this.refs['volumeRanger'].value=data.volume

    let slider = this.refs["volumeControlSlider"];
    let value = this.refs["volumeControlValue"];

    slider.style.top =  value.style.top = 84 - ((data.volume/100)*64) +"px"
    value.style.height = ((data.volume/100)*64)+16 +"px";

    let channelArray = this.state.channelNum === 2 ? this.state.channelLabels[0] : this.state.channelLabels[1]
    

    let channelType = data.channel;
    if(maxChannels <=2){
      switch (channelType) {
        case 'All':
          channelType = 'Both';
        break;
        case 'Front':
          channelType = 'Both';
        break;
        case 'Back':
          channelType = 'Both';
        break;
        case 'FrontLeft':
          case 'Left':
          channelType = 'Left';
        break;
        case 'FrontRight':
          case 'Right':
          channelType = 'Right';
        break;
        case 'BackLeft':
          channelType = 'Left';
        break;
        case 'BackRight':
          channelType = 'Right';
        break;
        default:
          channelType = 'Both';
    }
  }

  console.log('ac',channelArray.findIndex(c => c === channelType))



    this.setState(
      { volume: data.volume,
        channelType:channelType,
        channelIndex: channelArray.findIndex(c => c === channelType),
        fileNameToSave: data.effectName,
        type: data.type,
        drawerCurve: data.drawerCurve,
        content: myStatesContent,
        ownEffectActive: own
      },
      () => {
        this.callback();
      }
    );

   

    console.log('data: ',data.channel, 'mc: ', maxChannels )

    this.refs["ranger"].value = data.type;
    this.rangeChange();

    if (own) {
      // this.refs["burgerMenuDelete"].classList.remove(
      //  "saveBurgerDisabledMenuButton"
      //  );
      this.refs["burgerMenuDelete"].disabled = false;

    } else {
      // this.refs["burgerMenuDelete"].classList.add(
      //   "saveBurgerDisabledMenuButton"
      // );
      this.refs["burgerMenuDelete"].disabled = true;
    }
  }

  render() {
    // console.log(this.state.channelLabels,this.state.channelNum)


    let ltt = this.state.content.leftTopT;
    let lthz = this.state.content.leftTopHz * this.state.content.leftTopD;
    let ltfb = this.state.content.fileBuffer.leftTopT;
    let ltd = this.state.content.leftTopD;
    let mcl = keyArrays.getKeyArray(ltt, lthz, [1, 1], ltfb,this.state.content.flipv, this.state.content.fliph);
    let newMcl = [];

    let mySoundData = this.state.databaseSound;
    let mySoundDataDurations = [];

    mySoundData.map(data => {
      mySoundDataDurations.push(data.duration);
      if (typeof mcl[0] === "string" && data.soundName === mcl[0]) {
        return  mcl = data.fileBuffer;
      }else{
        return 'hi'
      }
    });

    if (this.state.content.envelopArrays.left) {
      for (let i = 0; i < mcl.length; i++) {
        let er = this.state.content.envelopArrays.left[0];
        let f =
          (250 - er[i]) / (250 - this.state.content.envelopBases.envelopLeft);

        newMcl[i] = mcl[i] * f;
      }
    }

    let grid = [];
    grid.length = this.state.content.columns;

    let env = "";
    let hArrows = "0";
    if (this.state.pressedKey === "h") {
      hArrows = "1.0";
    }

    for (let i = 1; i <= this.state.content.columns; i++) {
      let cc, tt, envelopValues;
      if (i === 1 && mcl.length >= 50000) {
        if (newMcl.length > 0) {
          cc = newMcl;
        } else {
          cc = mcl;
        }
        tt = ltd;
        envelopValues = this.state.content.envelopLeft;
        // onClick={this.click}
        env = (
          <div className="envelopCanvas" id="envelopCanvas01">
            <div
              className="handle"
              id="handle01"
              style={this.state.content.envelopLeft[0]}
              onMouseDown={this.startDragHandler}
            >
              <div className="handleArrowLeft"></div>
              <div className="handleMiddleColumn">
                <div className="handleArrowTop"></div>
                <div className="handlePoint"></div>
                <div className="handleArrowBottom"></div>
              </div>
              <div className="handleArrowRight"></div>
            </div>
            <div
              className="handle"
              id="handle02"
              style={this.state.content.envelopLeft[1]}
              onMouseDown={this.startDragHandler}
            >
              <div
                className="handleArrowLeft"
                style={{ opacity: hArrows }}
              ></div>
              <div className="handleMiddleColumn">
                <div className="handleArrowTop"></div>
                <div className="handlePoint"></div>
                <div className="handleArrowBottom"></div>
              </div>
              <div
                className="handleArrowRight"
                style={{ opacity: hArrows }}
              ></div>
            </div>
            <div
              className="handle"
              id="handle03"
              style={this.state.content.envelopLeft[2]}
              onMouseDown={this.startDragHandler}
            >
              <div
                className="handleArrowLeft"
                style={{ opacity: hArrows }}
              ></div>
              <div className="handleMiddleColumn">
                <div className="handleArrowTop"></div>
                <div className="handlePoint"></div>
                <div className="handleArrowBottom"></div>
              </div>
              <div
                className="handleArrowRight"
                style={{ opacity: hArrows }}
              ></div>
            </div>
            <div
              className="handle"
              id="handle04"
              style={this.state.content.envelopLeft[3]}
              onMouseDown={this.startDragHandler}
            >
              <div
                className="handleArrowLeft"
                style={{ opacity: hArrows }}
              ></div>
              <div className="handleMiddleColumn">
                <div className="handleArrowTop"></div>
                <div className="handlePoint"></div>
                <div className="handleArrowBottom"></div>
              </div>
              <div
                className="handleArrowRight"
                style={{ opacity: hArrows }}
              ></div>
            </div>
            <div
              className="handle"
              id="handle05"
              style={this.state.content.envelopLeft[4]}
              onMouseDown={this.startDragHandler}
            >
              <div
                className="handleArrowLeft"
                style={{ opacity: hArrows }}
              ></div>
              <div className="handleMiddleColumn">
                <div className="handleArrowTop"></div>
                <div className="handlePoint"></div>
                <div className="handleArrowBottom"></div>
              </div>
              <div
                className="handleArrowRight"
                style={{ opacity: hArrows }}
              ></div>
            </div>
            <div
              className="handle"
              id="handle06"
              style={this.state.content.envelopLeft[5]}
              onMouseDown={this.startDragHandler}
            >
              <div className="handleArrowLeft"></div>
              <div className="handleMiddleColumn">
                <div className="handleArrowTop"></div>
                <div className="handlePoint"></div>
                <div className="handleArrowBottom"></div>
              </div>
              <div className="handleArrowRight"></div>
            </div>
          </div>
        );
      }

      let myc = (
        <div className="gridElement" key={i}>
          <GridElement
            key={i}
            ref={"GridElement" + i}
            myCurve={cc}
            time={tt}
            envelop={envelopValues}
          />
          {env}
        </div>
      );

      grid[i] = myc;
    }
    // console.log(this.state.type);
    if(Number(this.state.type)===3){


    if(this.state.mouseDown){
      // console.log(this.state.mouseDown);
      // this.refs['drawer'].pathDraw()
    }else{

      // if(this.refs['drawer']!=undefined){this.refs['drawer'].pathStop()}
    // this.refs['drawer'].pathStop()
    } // this.refs['drawer'].test()
    };
    return (
      <div
        className="wrapper"
        id="wrapper"
        ref="wrapper"
        onMouseDown= {this.mouseDown}
        onMouseMove={this.dragDragHandler}
        onMouseUp={this.stopDragHandler}
      >
        <div className="fileConsole">
          <Filecontainer
            ref="fileContainer"
            id="fileContainer"
            updateContent={this.state.updateContent}
            switchToDownloadButton={this.switchToDownloadButton}
            loadEffect={this.loadEffect}
            usr={this.props.usr}
            device={this.state.deviceName}
            con={this.state.audioChannel}
            cont={this.state.channelType}
          />
        </div>
        <div className="flipTools">
          <small className="inputLabel soundControlLabel">Flip</small>
          <div className="flipH flip" name="flipH" ref="flipH" onClick={this.flipCurve}></div>
          <div className="flipV flip" name="flipV" ref="flipV" onClick={this.flipCurve}/>
        </div>
        <div className="grid">
          <div className="typeSelect">
            <div className="rangeLabels">
              <input
                className="ranger"
                id="ranger"
                ref="ranger"
                type="range"
                min="1"
                max="3"
                value={this.state.type}
                onChange={this.rangeChange}
              ></input>
              <div className="rangeButton curve rangeActive" id="rangeCurve">
                Curve
              </div>
              <div className="rangeButton file" id="rangeFile">
                File
              </div>
              <div className="rangeButton shape" id="rangeShape">
                Draw
              </div>
            </div>
          </div>
          <div className="editorTools">
            <div className="inputWrapper">
              <div className="topInput">
                <div id="curveSub" className="subElement activeSub">
                  <InputSelect
                    name="leftTopD"
                    range={3.0}
                    step={[0.1, 0.1, this.state.content.filetime[0]]}
                    label="sec"
                    onchange={this.change}
                    myValue={this.state.content.leftTopD}
                    id="leftTopD"
                  />
                  <InputSelect multiple="true"
                  name="leftTopHz"
                  range={300}
                  step={[5, 5,this.state.manualHz]}
                  label="HZ (max. 300)"
                  onchange={this.change}
                  myValue={this.state.content.leftTopHz}
                  onchangeT ={this.changeT}
                  id="leftTopD"
                  />
                  <InputSelect
                    name="leftTopT"
                    range={typeArray}
                    label="type"
                    onchange={this.change}
                    onclick={this.inputClick}
                    myValue={this.state.content.leftTopT}
                  />
                </div>
                <div id="fileSub" className="subElement">
                  <InputSelect
                    name="leftTopD"
                    range={3.0}
                    step={[0.1, 0.1, this.state.content.filetime[0]]}
                    label="sec"
                    onchange={this.change}
                    myValue={this.state.content.leftTopD}
                    id="leftTopD"
                  />
                  <button name="leftTopT" onClick={this.fileButtonClick}>
                    load File
                  </button>
                </div>
                <div id="shapeSub" className="subElement">
                  <InputSelect
                    name="leftTopD"
                    range={3.0}
                    step={[0.1, 0.1, this.state.content.filetime[0]]}
                    label="sec"
                    onchange={this.change}
                    myValue={this.state.content.leftTopD}
                    id="leftTopD"
                  />
                </div>
              </div>
            </div>
            {/*saveDropown*/}
            <div className="saveDropDown">
              <button
                onClick={this.openSaveBurger}
                ref="saveBurger"
                className="saveBurger"
              ></button>
              <div
                ref="saveBurgerMenu"
                style={{ display: "none" }}
                className="saveBurgerMenu"
              >
                <button
                  onClick={this.selectSaveBurgerItem}
                  id="burgerMenuSaveAs"
                  ref="burgerMenuSaveAs"
                  className="saveBurgerMenuButton"
                >
                  Save (as)
                </button>
                <button
                  onClick={this.selectSaveBurgerItem}
                  id="burgerMenuDownload"
                  ref="burgerMenuDownload"
                  className="saveBurgerMenuButton"
                >
                  Download
                </button>
                <button
                  onClick={this.selectSaveBurgerItem}
                  id="burgerMenuDelete"
                  ref="burgerMenuDelete"
                  className="saveBurgerMenuButton"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>


          <div className="yAxis">
            <small>1</small>
            <small>0</small>
            <small>-1</small>

          </div>

          <div className="gridelements" id="gridelements" ref="gridelements">
            {grid}
          </div>
          <div className="drawerBox" id="drawerBox" ref="drawerBox">
            <Drawer
              ref="drawer"
              preset=""
              myCurve={this.getDrawerData}
              canvasBound={this.canvasBound}
              mousePos={this.state.mousePos}
              mouseDown = {this.state.mouseDown}
              drawerChange={this.drawerChange}
            />
          </div>
          <div className="needle" ref="needle">
            <div className="needleMarker" />
          </div>
          <input
            className="myFileInput"
            type="file"
            id="myFileInput"
            name="avatar"
            accept="audio/mpeg, audio/wav, audio/m4a"
            onInput={this.getFileInfo}
          />
        </div>
        <div className="soundControl">
        <div className = "channelSelector">
            <small className="inputLabel soundControlLabel">Channels ({this.state.deviceName.length > 0 ? this.state.deviceName : 'computer audiooutput'})</small>
          {this.state.channelContent}
        </div>
        <div className = "volumeControl">
        <small className="inputLabel soundControlLabel">Volume</small>
        <div className="volumeControlLabel" >
            <div className="volumeControlLabelOver" ref="volumeLabel">
              <div className="volumeControlLabel3" ref="volume3" />
              <div className="volumeControlLabel2" ref="volume2" />
              <div className="volumeControlLabel1" ref="volume1" />
              <div className="volumeControlLabel0" ref="volume0" />
            </div>
        </div>

            <input
              className="volumeRanger"
              id="volumeRanger"
              ref="volumeRanger"
              type="range"
              min="0"
              max="100"
              value={this.state.volume}
              onChange={this.volumeChange}
            ></input>
            <div className="volumeControlBar"></div>
            <div className="volumeControlValue" ref="volumeControlValue"></div>
            <div className="volumeControlSlider" ref="volumeControlSlider"></div>

        </div>
        <div className="effectConsole">
          <div className="playConsole" id="playConsole">
            <div
              className="effectConsoleButton playButton"
              ref="playButton"
              id="playButton"
              onClick={this.getSound}
            ></div>
            <div
              className="effectConsoleButton loopButton"
              id="loopButton"
              onClick={this.setLoop}
            ></div>
          </div>
        </div>
        </div>
        <div className="saveConsoleWrapper" id="saveConsoleWrapper">
          <Saveconsole
            ref="saveConsoleWrapper"
            saveData={this.state.dataToSave}
            closeSaveConsole={this.closeSaveConsole}
          />
        </div>
        <div className="dragModifierHint" ref="dragModifierHint">
          {" "}
          'Press h-key for horizontal movement'
        </div>
        <div className="howToWrapper" ref="howToWrapper">
          <Howto showagain={true} usr={this.props.usr} setUp={this.setConnection} />
        </div>

      </div>
    );
  }
}

export default MasterElement;

//old savemenu
  // <Howto showagain={this.props.howto} usr={this.props.usr} />
// <div
//   className="effectConsoleButton"
//   id="saveButton"
//   onClick={this.saveSound}
// >
//   save
// </div>
// <div
//   className="effectConsoleButton"
//   id="downloadButton"
//   onClick={this.downloadSound}
// >
//   download
// </div>

//
// <datalist id="browsers">
// <option value="Internet Explorer" />
// <option value="Firefox"/>
// <option value="Chrome"/>
// <option value="Opera"/>
// <option value="Safari"/>
// </datalist>

import React, { Component } from "react";
import "./input_radio.css";

//import { Button, ButtonGroup, Form, FormGroup, Label, Input, FormText  } from 'reactstrap';

class InputRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: this.props.myRange,

      active: this.props.myActive
    };
    this.changeActive = this.changeActive.bind(this)
    this.beep = this.beep.bind(this)
  }

  componentDidMount(){
    // this.props.changeChannel(this.props.myRange[0]);
  
  }


  changeActive(e){
    let i = parseInt(e.target.getAttribute('myIndex'));
    this.setState({active: i})
    this.props.changeChannel(e.target.name);
    //console.log(e.target.name, i)
  }

  beep(b){
    console.log(b)
  }

  render() {
    // if(this.props.myActive !== this.state.active){
    // console.log('Active: ', this.props.myActive, this.state.active)
    // console.log(this.props.myRange[this.props.myActive])
    
  // }
    let myButtonGroup =[]
    let myRangeLength = this.props.myRange.length

    if(myRangeLength === 3){
    
    this.props.myRange.map((myButton,index)=>{
     
      myButtonGroup.push(
         <div key={index}>
         <label className="radioContainer inputLabel">
          <input type="radio" myindex={index} id={myButton} onChange={this.changeActive} name={myButton} checked={myButton===this.props.myRange[this.props.myActive]}></input>
          <span className="radiocheckmark"></span>
          {myButton}
        </label>

         </div>

       )
       return index;
    })
  }else{
    let coulumn1 = []
    let coulumn2 = []
    let coulumn3 = []

    this.props.myRange.map((myButton,index)=>{

      if(index === 0){
        coulumn1.push(
          <div key={index}>
            <label className="radioContainer inputLabel">
            <input type="radio" myindex={index} id={myButton} onChange={this.changeActive} name={myButton} checked={myButton===this.props.myRange[this.state.active]}></input>
            <span className="radiocheckmark"></span>
            {myButton}
            </label>
          </div>
        )   
      }else if( index < 4){
      coulumn2.push(
        <div key={index}>
          <label className="radioContainer inputLabel">
          <input type="radio" myindex={index} id={myButton} onChange={this.changeActive} name={myButton} checked={myButton===this.props.myRange[this.state.active]}></input>
          <span className="radiocheckmark"></span>
          {myButton}
          </label>
        </div>
      )   
      }else if(index < 7){
        coulumn3.push(
          <div key={index}>
            <label className="radioContainer inputLabel">
            <input type="radio" myindex={index} id={myButton} onChange={this.changeActive} name={myButton} checked={myButton===this.props.myRange[this.state.active]}></input>
            <span className="radiocheckmark"></span>
            {myButton}
            </label>
          </div>
        )  
      }
    
      return index
    })
    //  const columDiv =  // 
    myButtonGroup = <div className="complexRadiobuttonGroup">
      <div className="raudioButtonColumn">{coulumn1}</div>
      <div className="raudioButtonColumn">{coulumn2}</div>
      <div className="raudioButtonColumn">{coulumn3}</div>
      </div>
  }

    return (
      <div className="radioButtonbar">
      <form>
          {myButtonGroup}

      </form>
      </div>
    );
  }
}

export default InputRadio;

// <input type="radio" myindex={index}  id={myButton} onChange={this.changeActive} name={myButton} checked={myButton===this.state.range[this.state.active]} />
// <label>{myButton}</label>
// <Label for={this.props.name}>{this.props.label}</Label>

import React, { Component } from "react";
import "./reset.css";
import "./App.css";
import Container from "./components/container";
import Usersmanagement from "./components/usermanagement";
import ErrorBoundary from "./components/ErrorBoundary"
// import WrapperContent from './components/matrixcomponents/WrapperContent'

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// http://localhost:9000/newMatrix

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
      <Router>
        <Switch>
          <Route exact path="/" component={Container} />
          <Route
            path="/usermanagement/:email/:password"
            component={Usersmanagement}
          />
          {/* <Route path="/matrix"
              component={WrapperContent}
          /> */}

        </Switch>
      </Router>
      </ErrorBoundary>
    );
  }
}

export default App;

//
// <div>
//   <ul>
//     <li>
//       <Link to="/">Home</Link>
//     </li>
//     <li>
//       <Link to="/about">About</Link>
//     </li>
//     <li>
//       <Link to="/dashboard">Dashboard</Link>
//     </li>
//   </ul>
//
//   <hr />
//
//   {/*
// A <Switch> looks through all its children <Route>
// elements and renders the first one whose path
// matches the current URL. Use a <Switch> any time
// you have multiple routes, but you want only one
// of them to render at a time
// */}

import React, { Component } from "react";
import "./file_container.css";
import axios from "axios";
const API_URL = "";
const keyArrays = require("../req/keyarrays");

let player = require("../req/playSound");
let _ = require("lodash");
let beforeClasses = ["beforePrimitive", "beforeFile", "beforeDraw"];
let loadedData = [];
let mySource

class Filecontainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      curves: {},
      content: [],
      selected: "",
      loaded:false
    };
    this.createContent = this.createContent.bind(this);
    this.playEffect = this.playEffect.bind(this);
    this.loadEffect = this.loadEffect.bind(this);
    this.collapseList = this.collapseList.bind(this);
    this.deselect = this.deselect.bind(this);
    this.callback = this.callback.bind(this);
    this.callback2 = this.callback2.bind(this);
    this.mylogout = this.mylogout.bind(this);
    this.deleteEffect = this.deleteEffect.bind(this);
  }

  mylogout() {
    // console.log("filecontainer says logout");
    loadedData = [];
  }

  deleteEffect(e) {

    let title = this.state.selected;
    let conf = window.confirm(
      "Do you really want to delete your effect " + title + "?"
    );
    if (conf) {
      axios
        .post(API_URL + "deleteEffect?effectName=" + title)
        .then(response => {
          this.createContent(false);
          this.deselect("");
          return true;
        })
        .catch(err => {
          console.log("Fehler: ", err);
          return false;
        });
    } else {
      return false;
    }
  }

  createContent(e, en) {
    axios
      .get(API_URL + "getEffects")
      .then(response => {
        if (en) {
          this.setState({ saved: e, content: response, selected: en,loaded:false }, () => {


            this.callback();
          });
        } else {
          this.setState({ saved: e, content: response }, () => {


            this.callback();
          });
        }
      })
      .catch(err => {
        console.log("Fehler: ", err);
      });

    //console.log(this.state.content);

    if (e) {
    }
  }

  callback() {
    let checkLoad = [];
    let loadTotal = [];

    for (let i = 0; i < this.state.content.data.length; i++) {
      checkLoad.push(this.state.content.data[i].effectName);
    }

    loadTotal = _.difference(checkLoad, loadedData);

    if (loadTotal.length > 0) {
      axios
        .get(
          API_URL +
            "updateUnloaded?toload=" +
            loadTotal +
            "&saved=" +
            this.state.saved
        )
        .then(response => {
          let myCurves = this.state.curves;

          for (let i = 0; i < loadTotal.length; i++) {
            let d = response.data.data[i];
            myCurves[d.effectName] = d;
          }
          this.setState({ curves: myCurves }, () => {
              this.callback2();
          });
        })
        .catch(err => {
          console.log("Fehler: ", err);
        });
    } else {
      this.callback2();
    }

    loadedData = checkLoad;
  }

  callback2() {
    let myContent = this.state.content;
    for (let ii = 0; ii < this.state.content.data.length; ii++) {
      //if (this.state.content.data[ii].effectName === d.effectName) {
      let d = this.state.content.data[ii].effectName;
      // console.log(d,this.state.curves[d]);
       // if(myContent.data[ii].fileBuffer ){
      myContent.data[ii].fileBuffer = this.state.curves[d].fileBuffer;
       // }
       // if(myContent.data[ii].curve){
      myContent.data[ii].curve = this.state.curves[d].curve;
       // }
       // if(myContent.data[ii].envCurve){
      myContent.data[ii].envCurve = this.state.curves[d].envCurve;
       // }
      //  }
    }
    this.setState({
      loaded:true,
      content: myContent
    });
    // console.log(myContent);;
  }

  componentDidMount() {
    this.createContent(false);
  }

  playEffect(e) {
    let i = parseInt(e.target.parentElement.getAttribute("index"));
    let mc = this.state.content.data[i];

    let c
    let nc=[];
    if(mc.type===3){
        c = mc.drawerCurve.bufferCurve
    }else{
      let fileBufferString = Buffer.from(mc.fileBuffer.data).toString('utf8');
      let fileBufferData = fileBufferString.split(',')
      _.forEach(fileBufferData, function(value,index){
      fileBufferData[index] =  parseFloat(fileBufferData[index])});

      // keyArrays.envArray(
        // myContent.envelopLeft.slice(0, 6)
      
      //  console.log(f,eb);


       c = keyArrays.getKeyArray(mc.curveType, mc.curveFrequency*mc.duration, [1, 1], fileBufferData, mc.flip.h, mc.flip.v);

       
    }

    let f = keyArrays.envArray(
      mc.envPoints.slice(0, 6)
    );

    let eb = mc.envBase

    for (let i = 0; i < c.length; i++) {
      // let er = this.state.content.envelopArrays.left[0];
      let ff =(250 - f[0][i]) / (250 - eb);
      nc[i] = c[i] * ff;
    }
    
  
    console.log(mc)

    // console.log('filecontainer: ',c.length);

    let myPlayer = player.playSound(
      nc,
      mc.duration,
      false,
      this.props.con,
      mc.volume,
      mc.channel
    );

    mySource = player.playSource(
      nc,
      mc.duration,
      false,
      this.props.con,
      mc.volume,
      mc.channel,
      false,
      this.props.device,
      mc.volume/100.0
    )

   

      // myPlayer.play();

    //var playPromise = mySource.start();

    mySource.start()
    
    //myPlayer.play();

  // if (playPromise !== undefined) {
    
  //   playPromise.then(_ => {
  //     // Automatic playback started!
  //     // Show playing UI.
  //   })
  //   .catch(error => {
  //     mySource.start()
  //   });
  // }

    mySource.onended = () => {
      // mySource.pause();
      // mySource.currentTime = 0;
    };
  }

  deselect(e) {
    let listitems = document.getElementsByClassName("effectListItem");

    for (let o = 0; o < listitems.length; o++) {
      listitems[o].children[0].style.display = "inline";
      listitems[o].children[2].style.display = "inline";
      if (listitems[o].children.length === 4) {
        listitems[o].children[3].style.display = "inline";
      }
      if (listitems[o].classList.length > 1) {
        if (listitems[o].classList[1] === "activeEffectListItem") {
          listitems[o].classList.remove("activeEffectListItem");
        } else {
          listitems[o].classList.remove("aboveActiveEffectListItem");
        }
      }
      if (e !== "") {
        if (e.parentElement.previousSibling) {
          e.parentElement.previousSibling.classList.add(
            "aboveActiveEffectListItem"
          );
        }

        e.parentElement.children[0].style.display = "none";
        e.parentElement.children[2].style.display = "none";
        if (e.parentElement.children.length === 4) {
          e.parentElement.children[3].style.display = "none";
        }
        // e.parentElement.style.justifyContent = "space-between";
        e.parentElement.classList.add("activeEffectListItem");

        this.props.switchToDownloadButton(true);
      } else {
        this.props.switchToDownloadButton(false);
      }
    }
  }

  loadEffect(e) {
    this.createContent(false);
    let i = parseInt(e.target.parentElement.getAttribute("index"));

    this.setState({ selected: e.target.parentElement.getAttribute("etitle") });


    // console.log(this.state.content.data[i]);

    this.props.loadEffect(
      this.state.content.data[i],
      e.target,
      e.target.parentElement.id.substring(0, 8) === "myEffect"
    );

    let listitems = document.getElementsByClassName("effectListItem");
    for (let o = 0; o < listitems.length; o++) {
      listitems[o].children[0].style.display = "inline";
      listitems[o].children[2].style.display = "inline";
      if (listitems[o].children.length === 4) {
        listitems[o].children[3].style.display = "inline";
      }
      if (listitems[o].classList.length > 1) {
        if (listitems[o].classList[1] === "activeEffectListItem") {
          listitems[o].classList.remove("activeEffectListItem");
        } else {
          listitems[o].classList.remove("aboveActiveEffectListItem");
        }
      }
    }
    if (e.target.parentElement.previousSibling) {
      e.target.parentElement.previousSibling.classList.add(
        "aboveActiveEffectListItem"
      );
    }

    e.target.parentElement.children[0].style.display = "none";
    e.target.parentElement.children[2].style.display = "none";
    if (e.target.parentElement.children.length === 4) {
      e.target.parentElement.children[3].style.display = "none";
    }
    // e.target.parentElement.style.justifyContent = "space-between";

    e.target.parentElement.classList.add("activeEffectListItem");

    // console.log(e.target.parentElement);
  }

  collapseList(e) {
    let myList = document.getElementById(e.target.id + "List");
    let myListDisplay = myList.style.display;

    if (myListDisplay === "flex") {
      document.getElementById(e.target.id + "List").style.display = "none";
      e.target.classList.add("listExpand");
    } else {
      document.getElementById(e.target.id + "List").style.display = "flex";
      e.target.classList.remove("listExpand");
    }
  }

  render() {
    let sampleEffects = [];
    let allEffects = [];
    let myEffects = [];
    let topTenEffects = [];
    let topTenEffectsTitle = "Other Effects";
    let content = this.state.content.data;
    if (content && this.state.loaded) {
      content.map((effect, index) => {
        let myDate = effect.timestamp.split("T");
        myDate[0] = myDate[0].split("-");
        myDate[0] = myDate[0][2] + "." + myDate[0][1] + "." + myDate[0][0];
        myDate[1] = myDate[1].substring(0, myDate[1].length - 5);
        myDate = myDate[0] + " | " + myDate[1];
        if (effect.sample) {
          return sampleEffects.push([effect.effectName, index, effect.type]);
        } else {
        return  allEffects.unshift([
            effect.effectName,
            index,
            myDate,
            effect.userId,
            effect.type
          ]);
        }
      });

      myEffects = allEffects.filter(effect => effect[3] === this.props.usr);
      allEffects = allEffects.filter(effect => effect[3] !==this.props.usr);
      if (allEffects.length < 10) {
        topTenEffectsTitle = "Other Effects";
        topTenEffects = allEffects;
      } else {
        // topTenEffects = allEffects.slice(
        //   allEffects.length - 11,
        //   allEffects.length
        // );
      }
      sampleEffects.sort();
      let sampleMaskshaderMargin,
        myEffectMaskshaderMargin,
        topTenMaskshaderMargin;
      if (sampleEffects.length > 4) {
        sampleMaskshaderMargin = "17.9375rem";
      } else {
        sampleMaskshaderMargin = sampleEffects.length * 4.05 + "rem";
      }
      if (myEffects.length > 4) {
        myEffectMaskshaderMargin = "17.9375rem";
      } else {
        myEffectMaskshaderMargin = myEffects.length * 4.05 + "rem";
      }
      if (topTenEffects.length > 4) {
        topTenMaskshaderMargin = "17.9375rem";
      } else {
        topTenMaskshaderMargin = topTenEffects.length * 4.05 + "rem";
      }

      // let myFirstElement;
      let myEmptyEffects = "";
      if (myEffects.length === 0) {
        myEmptyEffects = (
          <p className="emptyeEffectListItem">
            You still have no own saved effects.
          </p>
        );
      }

      let otherEmptyEffects = "";
      if (allEffects.length === 0) {
        otherEmptyEffects = (
          <p className="emptyeEffectListItem">
            Currently there are no other effects saved.
          </p>
        );
      }

      return (
        <div className="fileContainer">
          <div className="selectedEffects">
            <div className="effectListHeader">
              <h2>Sample Effects</h2>
              <div
                className="listCollapse"
                id="sampleEffect"
                onClick={this.collapseList}
              ></div>
            </div>
            <div
              className="effectList sampleEffects"
              style={{ display: "flex" }}
              id="sampleEffectList"
            >
              {sampleEffects.map((sampleEffect, index) => {
                return (
                  <div
                    className="effectListItem"
                    key={index}
                    index={sampleEffect[1]}
                    id={"sampleEffect_" + sampleEffect[1]}
                  >
                    <div
                      className="effectListItemTool effectListItemToolPlay"
                      onMouseDown={this.playEffect}
                    />
                    <div className="effectListItemLabel">
                      <p
                        className={
                          "effectTitle " +
                          beforeClasses[
                            sampleEffect[sampleEffect.length - 1] - 1
                          ]
                        }
                      >
                        {sampleEffect[0]}
                      </p>
                    </div>
                    <div
                      className=" effectListItemTool effectListItemToolEdit"
                      onMouseDown={this.loadEffect}
                    />
                  </div>
                );
              })}
              <div
                className="maskShader"
                style={{ marginTop: sampleMaskshaderMargin }}
              ></div>
            </div>

            <div className="effectListHeader">
              <h2>Your Effects</h2>
              <div
                className="listCollapse"
                id="userEffect"
                onClick={this.collapseList}
              ></div>
            </div>
            <div
              className="effectList userEffects"
              style={{ display: "flex" }}
              id="userEffectList"
            >
              {myEffects.map((myEffect, index) => {
                if (this.state.selected === myEffect[0]) {
                  return (
                    <div
                      className="effectListItem activeEffectListItem"
                      key={index}
                      index={myEffect[1]}
                      id={"myEffect_" + myEffect[1]}
                      etitle={myEffect[0]}
                    >
                      <div
                        className="effectListItemTool effectListItemToolPlay"
                        style={{ display: "none" }}
                        onMouseDown={this.playEffect}
                      />
                      <div className="effectListItemLabel myEffectLabel">
                        <p
                          className={
                            "effectTitle " +
                            beforeClasses[myEffect[myEffect.length - 1] - 1]
                          }
                        >
                          {myEffect[0]}
                        </p>
                        <small className="effectDate">{myEffect[2]}</small>
                      </div>
                      <div
                        className=" effectListItemTool effectListItemToolEdit"
                        style={{ display: "none" }}
                        onMouseDown={this.loadEffect}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="effectListItem"
                      key={index}
                      index={myEffect[1]}
                      id={"myEffect_" + myEffect[1]}
                      etitle={myEffect[0]}
                    >
                      <div
                        className="effectListItemTool effectListItemToolPlay"
                        onMouseDown={this.playEffect}
                      />
                      <div className="effectListItemLabel myEffectLabel">
                        <p
                          className={
                            "effectTitle " +
                            beforeClasses[myEffect[myEffect.length - 1] - 1]
                          }
                        >
                          {myEffect[0]}
                        </p>
                        <small className="effectDate">{myEffect[2]}</small>
                      </div>
                      <div
                        className=" effectListItemTool effectListItemToolEdit"
                        onMouseDown={this.loadEffect}
                      />
                    </div>
                  );
                }
              })}
              <div ref="myEmptyEffects">{myEmptyEffects}</div>
              <div
                className="maskShader"
                style={{ marginTop: myEffectMaskshaderMargin }}
              ></div>
            </div>
            <div className="effectListHeader">
              <h2> {topTenEffectsTitle}</h2>
              <div
                className="listCollapse"
                id="lastEffect"
                onClick={this.collapseList}
              ></div>
            </div>
            <div className="effectList lastEffects" id="lastEffectList">
              {topTenEffects.map((myEffect, index) => {
                return (
                  <div
                    className="effectListItem"
                    key={index}
                    index={myEffect[1]}
                    id={"topTenEffects_" + myEffect[1]}
                  >
                    <div
                      className="effectListItemTool effectListItemToolPlay"
                      onMouseDown={this.playEffect}
                    />
                    <div className="effectListItemLabel">
                      <p
                        className={
                          "effectTitle " +
                          beforeClasses[myEffect[myEffect.length - 1] - 1]
                        }
                      >
                        {myEffect[0]}
                      </p>
                      <small className="effectDate">{myEffect[2]}</small>
                    </div>

                    <div
                      className=" effectListItemTool effectListItemToolEdit"
                      onMouseDown={this.loadEffect}
                    />
                  </div>
                );
              })}
              <div ref="myEmptyEffects">{otherEmptyEffects}</div>
              <div
                className="maskShader"
                style={{ marginTop: topTenMaskshaderMargin }}
              ></div>
            </div>
          </div>
          <div className="allEffects">
            <h2>All Effects</h2>
          </div>
        </div>
      );
    } else {
      return (<div className="fileContainerUpdate">
        <div className="loadingIndicatorBall">
          <div className="loadingIndicatorBallHand"></div>
        </div>
          <p className="updateHead">content loading</p>
      </div>);
    }
  }
}

export default Filecontainer;


import React, { Component } from "react";
import "./ErrorBoundary.css";



class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };


  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }



  render() {
    if (this.state.hasError) {
      return(
      <div className="errorWrapper">
      <div className="errorContent">
            <h1>We apologise for the inconvenience, but an error has occurred. <br/>Please reload the pulseDesigner and log in again.</h1>
          </div>
      </div>
       )
       
    }

     return this.props.children;
  }
}


export default ErrorBoundary;

import { interpolateArray } from "./filebuffer";

let deviceList, mediaEnabled, selectedDevice;
let audioCtx //= new window.AudioContext();
var synthDelay //= audioCtx.createDelay(5.0);
let audio;
let toWav = require("audiobuffer-to-wav");




export function checkcheckConnection() {
  (async () => {

    // audio: { deviceId: myPreferredCameraDeviceId }
    await navigator.mediaDevices.getUserMedia({ audio: true }).then(() => { mediaEnabled = true; }).catch(() => { mediaEnabled = false; });
    await navigator.mediaDevices.enumerateDevices().then(() => { return newMediaCheck })
      .catch(handleError);

  })();

}



export function checkConnection() {
  return deviceList
}



// navigator.mediaDevices
//   .enumerateDevices()
//   .then(newMediaCheck)
//   .catch(handleError);

function handleError(error) {
  return console.log(
    "navigator.MediaDevices.getUserMedia error: ",
    error.message,
    error.name
  );
}

function newMediaCheck(deviceList) {
  console.log('media: ',deviceList)
  let device = deviceList.filter(
    device =>
      device.label.slice(0, 12) === "PulseCoreDev" &&
      device.kind === "audiooutput"
    //device.deviceId === "default" && device.kind === "audiooutput"
  );
  if (device.length === 0) {
    selectedDevice = false;
  } else {
    selectedDevice = device;
  }
  let browserIsChrome = navigator.userAgent.indexOf("Chrome") !== -1;

  deviceList = { browserIsChrome, mediaEnabled, selectedDevice };
}

export function checkMaxChannelNum(){
  if (audioCtx === undefined) {
    audioCtx = new window.AudioContext();
  }
  const maxChannels = audioCtx.destination.maxChannelCount;
  return (maxChannels)
}

export function playSound(mc, t, myloop, mychannel, volume, channelType, getBuffer,deviceName) {

  if (audioCtx === undefined) {
    audioCtx = new window.AudioContext();
  }

  synthDelay = audioCtx.createDelay(5.0);
  audioCtx.destination.channelCountMode = 'max';
  const maxChannels = audioCtx.destination.maxChannelCount;
  audioCtx.destination.channelCount = maxChannels;

  selectedDevice = mychannel;

  // console.log(channelType)
  
  let newBuffer = interpolateArray(mc, audioCtx.sampleRate * t);
  let myArrayBuffer = audioCtx.createBuffer(
    2,
    audioCtx.sampleRate * t,
    audioCtx.sampleRate
  );

  //let channelBorders = [];

  // switch (channelType) {
  //   case 'All':
  //     channelBorders = [0,3]
  //     break;
  //   case 'Both':
  //   case 'Front':
  //     channelBorders = [0,1]
  //     break
  //   case 'FrontLeft':
  //   case 'Left':
  //     channelBorders = [0]
  //     break
  //   case 'FrontRight':
  //   case 'Right':
  //     channelBorders = [1]
  //     break;
  //   case 'Back':
  //     channelBorders = [2,3]
  //     break;
      
  //   case 'BackLeft':
  //     channelBorders = [2]
  //     break;
  //   case 'BackRight':
  //     channelBorders = [3]
  //     break;
    
  //   default:
  //     channelBorders = [0,maxChannels]
  // }

  // console.log(maxChannels, myArrayBuffer.numberOfChannels)

  //let gains = [];

  for (var channel = 0; channel < 2; channel++) {
    let myC;

    // if(maxChannels<=2){
    //   switch (channelType) {
    //       case 'Left':
    //         myC = 0
    //         break;
    //       case 'Right':
    //         myC = 1;
    //         break;
    //       default:
    //         myC = channel;
    //     }
    // }else{


    // }



    // switch (channelType) {
    //   case 'All':
    //   case 'Both':
    //   case 'Front':
    //   case 'Back':
    //     myC = channel;
    //     break;
    //   case 'FrontLeft':
    //   case 'Left':
    //   case 'BackLeft':
    //     myC = 0
    //     break;
    //   case 'FrontRight':
    //   case 'Right':
    //     case 'BackRight':
    //     myC = 1;
    //     break;
    //   default:
    //     myC = channel;
    // }

    // if(deviceName ==='DualSense Controller' &&  channelType==='Right'){
    //   myC = 0;
    //   console.log('dual sense right')
    // }

    //myC = 1;
    var nowBuffering = myArrayBuffer.getChannelData(myC);
    for (var i = 0; i < myArrayBuffer.length; i++) {
      nowBuffering[i] = newBuffer[i];
    }

    //gains[channel] = audioCtx.createGain();
    // console.log('mein kanal: ',myC)
    //console.log('myChannel: ',myC,'myChannelDATA: ',channel,`\n ********`, myArrayBuffer.getChannelData(channel))
  }

  // var gainNode = audioCtx.createGain();
  // gainNode.gain.setValueAtTime(0, audioCtx.currentTime);

  var source = audioCtx.createBufferSource();
  source.buffer = myArrayBuffer;
  
  source.connect(synthDelay);

  let merger = audioCtx.createChannelMerger(4)

  source.connect(merger, 0,3)
  // var splitter = audioCtx.createChannelSplitter(maxChannels);

  source.start();

  // source.connect(splitter);
  // for (var m = 0; m < maxChannels; m++) {
  //     splitter.connect(gains[m], m);
  //     gains[m].connect(audioCtx.destination);
  // }

  // gains[0].gain.value = 1; // Kanal 1 
  // gains[1].gain.value = 0; // Kanal 
  // gains[2].gain.value = 0; // Kanal 
  // gains[3].gain.value = 0; // Kanal 

  // switch(channelType){
  //   case 'Stereo':
  //     panNode.pan.value = 0;
  //   break;
  //   case 'Left':
  //     panNode.pan.value = -1;
  //   break;
  //   case 'Right':
  //     panNode.pan.value = 1;
  //   break;
  //   default:
  //     panNode.pan.value = 0;
  // }
  // console.log(gains[1])
  // return source.start()



  


  // source.connect(gainNode);
  // gainNode.connect(audioCtx.destination);
  //
  // gainNode.gain.exponentialRampToValueAtTime(0.1, audioCtx.currentTime + 0.2);

  var wav = toWav(myArrayBuffer);

  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.style = "display: none";
  let newAudio = new window.Blob([new DataView(wav)], {
    type: "audio/wav"
  });
  let url = URL.createObjectURL(newAudio);

  audio = new Audio(); //document.createElement('audio');
  const mSource = document.createElement("source");
  audio.volume = parseFloat(volume / 100)
  audio.src = url; //audio.appendChild(mSource);

  mSource.src = url;
  mSource.type = "audio/wav";

  if (selectedDevice) {
    audio.setSinkId(selectedDevice[0].deviceId);
  } else {
    // alert("Please connect pulsoCore Samplebox");
  }

  //audio.src=url;

  //audio.loop = myloop;
  // audio.play();
  if (getBuffer) {
    // return myArrayBuffer
    console.log('getBuffer: ',myArrayBuffer);

  }
  return audio;
  // audio.onended = () => {
  //   console.log("end");
  //   return "end";
  // };

  // let url = window.URL.createObjectURL(newAudio)
  // anchor.href = url;
  // anchor.download = "audio.wav";
  // anchor.click();
  // window.URL.revokeObjectURL(url);
}





export function stopSound() {
  audio.pause();
  audio.currentTime = 0;
}

export function downloadSound(mc, t, name, channelType) {
  if (audioCtx === undefined) {
    audioCtx = new window.AudioContext();
    synthDelay = audioCtx.createDelay(5.0);
  }



  let newBuffer = interpolateArray(mc, audioCtx.sampleRate * t);
  let myArrayBuffer = audioCtx.createBuffer(
    2,
    audioCtx.sampleRate * t,
    audioCtx.sampleRate
  );
  for (var channel = 0; channel < myArrayBuffer.numberOfChannels; channel++) {

    let myC;

    switch (channelType) {
      case 'Stereo':
        myC = channel;
        break;
      case 'Left':
        myC = 0
        break;
      case 'Right':
        myC = 1;
        break;
      default:
        myC = channel;
    }
    var nowBuffering = myArrayBuffer.getChannelData(myC);
    for (var i = 0; i < myArrayBuffer.length; i++) {
      nowBuffering[i] = newBuffer[i]; //Math.random() * 2 - 1;
    }
  }
  var source = audioCtx.createBufferSource();
  source.buffer = myArrayBuffer;
  var wav = toWav(myArrayBuffer);

  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.style = "display: none";
  let newAudio = new window.Blob([new DataView(wav)], {
    type: "audio/wav"
  });
  let url = window.URL.createObjectURL(newAudio);

  audio = new Audio(); //document.createElement('audio');
  const mSource = document.createElement("source");
  audio.src = url; //audio.appendChild(mSource);

  mSource.src = url;
  mSource.type = "audio/wav";

  // let url = window.URL.createObjectURL(newAudio)
  anchor.href = url;
  let myname = "audio.wav";
  if (name !== "") {
    myname = name + ".wav";
  }
  anchor.download = myname;
  anchor.click();
  window.URL.revokeObjectURL(url);
}



export function playSource(mc, t, myloop, mychannel, volume, channelType, getBuffer,deviceName,gain,loop) {

  if (audioCtx === undefined) {
    audioCtx = new window.AudioContext();
  }
  let myGain = audioCtx.createGain()
  if(gain){
    myGain.gain.value = gain;//setValueAtTime(gain,0.5);
  }else{
    myGain.gain.value = 1;
  }

  

  synthDelay = audioCtx.createDelay(5.0);
  audioCtx.destination.channelCountMode = 'max';
  const maxChannels = audioCtx.destination.maxChannelCount;
  audioCtx.destination.channelCount = maxChannels;

  selectedDevice = mychannel;
  
  let newBuffer = interpolateArray(mc, audioCtx.sampleRate * t);
  let myArrayBuffer = audioCtx.createBuffer(
    maxChannels,
    audioCtx.sampleRate * t,
    audioCtx.sampleRate
  );


  //let gains = [];

  for (var channel = 0; channel < maxChannels; channel++) {

    var nowBuffering = myArrayBuffer.getChannelData(channel);
    for (var i = 0; i < myArrayBuffer.length; i++) {
      nowBuffering[i] = newBuffer[i];
    }
  }

  var source = audioCtx.createBufferSource();
  source.buffer = myArrayBuffer;
  
  

  let splitter = audioCtx.createChannelSplitter(2)

  source.connect(splitter)


  console.log('maxChannels: ', maxChannels)
  if(maxChannels <=2){
    switch (channelType) {
      case 'All':
        channelType = 'Both';
      break;
      case 'Front':
        channelType = 'Both';
      break;
      case 'Back':
        channelType = 'Both';
      break;
      case 'Left':
      case 'FrontLeft':
        channelType = 'Left';
      break;
      case 'Right':
      case 'FrontRight':
        channelType = 'Right';
      break;
      case 'BackLeft':
        channelType = 'Left';
      break;
      case 'BackRight':
        channelType = 'Right';
      break;
      default:
        channelType = 'Both';
  }
}




  let merger = audioCtx.createChannelMerger(4)
  if(deviceName === 'DualSense Controller'){
    switch (channelType) {
      case 'All':
        splitter.connect(merger, 0,2)
        splitter.connect(merger, 1,3)
        break;
      case 'Left':
        splitter.connect(merger, 0,2)
        break;
      case 'Right':
        splitter.connect(merger, 1,3)
        break;
      default:
        splitter.connect(merger, 0,2)
        splitter.connect(merger, 1,3)
    }
  }else{
  switch (channelType) {
      case 'All':
        splitter.connect(merger, 0,0)
        splitter.connect(merger, 1,1)
        splitter.connect(merger, 0,2)
        splitter.connect(merger, 1,3)
        break;
      case 'Both':
      case 'Front':
        splitter.connect(merger, 0,0)
        splitter.connect(merger, 1,1)
        break;
      case 'Back':
        splitter.connect(merger, 0,2)
        splitter.connect(merger, 1,3)
        break;
      case 'FrontLeft':
      case 'Left':
        splitter.connect(merger, 0,0)
        break;
      case 'BackLeft':
        splitter.connect(merger, 0,2)
        break;
      case 'FrontRight':
      case 'Right':
        splitter.connect(merger, 0,1)
        break;
        case 'BackRight':
          splitter.connect(merger, 1,3)
        break;
      default:
        splitter.connect(merger, 0,0)
        splitter.connect(merger, 1,1)
        splitter.connect(merger, 0,2)
        splitter.connect(merger, 1,3)
   }
  }



  // merger.connect(audioCtx.destination)

  merger.connect(synthDelay);
  merger.connect(myGain);
  myGain.connect(audioCtx.destination)

  
  if(loop){
    source.loop = loop;
    source.loopStart = 0;
    source.loopEnd = 1.0

  }else{
    source.loop = false
  }

  // source.start();
  return source

}

export function stopSourcs(source) {
  source.pause();
  source.currentTime = 0;
}
import React, { Component } from "react";
import "./saveConsole.css";
import axios from "axios";
const API_URL = "";
let _ = require("lodash");


class Saveconsole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      effectName: "",
      effectTags: []
    };

    this.InputChange = this.InputChange.bind(this);
    this.SaveEffect = this.SaveEffect.bind(this);
    this.cancelSaveEffect = this.cancelSaveEffect.bind(this);
    this.switchToDefaultView = this.switchToDefaultView.bind(this);
  }

  componentDidMount() {}

  InputChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  SaveEffect() {
    let exclusion = [",", ":", "/", "\\"];

    for (let i = 0; i < exclusion.length; i++) {
      if (this.state.effectName.includes(exclusion[i])) {
        return alert("Please don't use the following Characters: \n , : / \\");
      }
    }

    let intFileBuffer = "";


    let dataToSave = this.props.saveData;


    _.forEach(dataToSave.fileBuffer, function(value,index) {
      if(index!==0){
          intFileBuffer += ','
        }
        intFileBuffer +=  dataToSave.fileBuffer[index]
    });
    //
    //
    // console.log(intFileBuffer);
    //
     dataToSave.file = Buffer.from([]);
     dataToSave.fileBuffer = Buffer.from(intFileBuffer)
     // dataToSave.curve = [];
     // dataToSave.envCurve = [];
    dataToSave.effectName = this.state.effectName;
    dataToSave.effectTags = this.state.effectTags;
    if (this.state.effectName !== "") {
      this.refs["processIndicator"].style.display = "flex";
      this.refs["saveForm"].style.display = "none";
    }
    // let time = new Date();
    // let offT = time.getTimezoneOffset();
    //console.log(offT);
    dataToSave.timestamp = Date.now();
    axios
      .post(API_URL + "saveEffect", dataToSave)
      .then(response => {
        if (!response.data.success) {
          this.refs["processIndicator"].style.display = "none";
          this.refs["saveForm"].style.display = "flex";
          if (response.data.message !== "confirm") {
            alert(response.data.message);
          } else {
            console.log("confirm");
            let conf = window.confirm(
              "Do you really want to overwrite your own effect " +
                dataToSave.effectName +
                "?"
            );
            if (conf) {
              axios.post(API_URL + "/updateEffect", dataToSave);
              this.props.closeSaveConsole(true, dataToSave.effectName);
            }
          }
        } else {
          this.props.closeSaveConsole(true, dataToSave.effectName);
        }
      })
      .catch(err => {
        this.refs["processIndicator"].style.display = "none";
        this.refs["saveForm"].style.display = "flex";
        console.log("Error: ", err);
      });
  }

  switchToDefaultView() {
    this.refs["processIndicator"].style.display = "none";
    this.refs["saveForm"].style.display = "flex";
  }

  cancelSaveEffect() {
    this.props.closeSaveConsole(false);
  }

  render() {
    // const effectTags = "";
    // this.state.effectTags.map(tag => {
    //   console.log(tag);
    // });
    return (
      <div className="saveConsole">
        <div className="saveForm" ref="saveForm" id="saveEffect">
          <h2 className="saveHead">Save Effect as</h2>

          <input
            className="nameInput logInInput"
            type="text"
            placeholder="Effect Name"
            value={this.state.effectName}
            id="effectName"
            onChange={this.InputChange}
            pattern="[A-Za-z0-9_]"
          />
          <input
            className="tagInput logInInput"
            type="text"
            placeholder="Tags (comma seperated)"
            value={this.state.effectTags}
            id="effectTags"
            onChange={this.InputChange}
          />
          <div className="buttonBar">
            <input
              className="submitSave submitLogin"
              type="button"
              value="Cancel"
              name="saveButton"
              id="signin"
              onClick={this.cancelSaveEffect}
            />
            <input
              className="submitSave submitLogin"
              type="submit"
              value="Save Effect"
              name="saveButton"
              id="signin"
              onClick={this.SaveEffect}
            />
          </div>
        </div>
        <div className="processIndicator" ref="processIndicator">
          <div className="processIndicatorBall">
            <div className="processIndicatorBallHand"></div>
          </div>
          <h2 className="saveHead">saving</h2>
        </div>
      </div>
    );
  }
}

export default Saveconsole;
